import Vue from 'vue'
import App from './App.vue'
import router from './router'
import http from './http/http'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import store from "./store";
import myNav from '@/components/myNav'
import loginPop from '@/components/loginPop/'
import vipPop from '@/components/vipPop/index'
Vue.component('myNav',myNav)
Vue.component('loginPop',loginPop)
Vue.component('vipPop',vipPop)
Vue.use(ElementUI)
Vue.config.productionTip = false
// 给原型对象添加方法
Vue.prototype.$axios = http
 // http.defaults.baseURL = '/api'
 // http.defaults.headers.post['Content-Type'] = 'application/json';
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
