<template>
	<div>
		<myNav/>
        <login-pop/> <!-- 未登录弹出 -->
		<div class="content">
			<div class="h_content" v-for="(item,index) in vip_list" :key="index" >
				<div class="a_all">
                    <el-image
                        style="width: 300px;"
                        :src="item.img"
                        fit="contain"></el-image>
                    <div class="a2_all">
                        <div class="name">{{item.name}}</div>
                        <div class="price"><span class="c_price">{{item.money}}</span></div>
                        <div class="vipAuth">VIP特权 <i class="iconfont icon-c-tequan"></i></div>
                        <div class="authList">
                            <span v-for="(intr_item,intr_index) in item.introduce" :key="intr_index">{{(intr_index == 0 ? '' : '、') + intr_item.name}}</span>
                        </div>
                        <div class="b_all">
                            <div class="b_title1" @click="pay(item)">立即购买</div>
                            <div class="b_title2" @click="jihuo(item)">立即激活</div>
                        </div>
                    </div>
                </div>
			</div>
		</div>
		<Footer/>
	</div>
</template>

<script>
	import Footer from '@/components/footer'
	export default {
		components: {
			Footer
		},
		props: ['myValue'],
		data() {
			return {
				yesData:[{name:'是'},{name:'否'}],
				check:0,
                vip_list: []
			}
		},
		activated(){
            this.$axios({
                url: 'member/PersonalVip',
                method: 'POST',
                data: {member_id: this.$store.state.userData.user_id}
            }).then(res => {
               this.vip_list = res.data.data
            })
		},
		methods: {
			isCheck(e){
				this.check=e
			},
            //VIP支付
            pay(e){
				this.$store.commit('getVipData',e)//保存vip跳转数据
                this.$router.push({path:'/PayFor',query:{id:e.id}})
            },
            //立即激活
            jihuo(e){
                this.$router.push({path:'/oderVip',query:{id:e.id}})
            }
		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
	.content {
		.h_content{
			width:1200px;
			background: #FFFFFF;
			padding: 26px 51px;
			box-sizing: border-box;
            .a_all{
                display: flex;
                align-items: center;
                .a2_all{
                    margin-left: 20px;
                    width: 800px;
                    .name {
                        font-size: 20px;
                        font-weight: 500;
                        color: #337CFF;
                    }
                    .price {
                        margin-top: 10px;
                        .c_price {
                            font-size: 20px;
                            font-weight: 400;
                            color: #F94D28;
                        }
                    }
                    .vipAuth {
                        font-size: 18px;
                        font-weight: 500;
                        color: #333333;
                        display: flex;
                        align-items: center;
                        .iconfont {
                            font-size: 33px;
                            color: #FDAE26;
                            margin-left: 5px;
                        }
                    }
                    .authList {
                        font-size: 16px;
                        font-weight: 400;
                        color: #666666;
                        margin-top: 5px;
                    }
                 .b_all{
                     display: flex;
                     align-items: center;
                     margin-top: 20px;
					.b_title1:hover{
						cursor: pointer;
					}
					.b_title2:hover{
						cursor: pointer;
					}
                     .b_title1{
                        width: 140px;
                        height: 50px;
                        background: #337CFF;
                        border-radius: 4px;
                        margin-right: 20px;
                        font-size: 16px;
                        font-weight: 400;
                        color: #FFFFFF;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                     }
                     .b_title2{
                        width: 140px;
                        height: 46px;
                        border: 2px solid #337CFF;
                        border-radius: 4px;
                        font-size: 16px;
                        font-weight: 400;
                        color: #337CFF;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                     }
                 }
                }
            }
		}
	}
</style>
