<template>
	<div>
		<div>
			<myNav/>
			<login-pop/> <!-- 未登录弹出 -->
			<div class="a_content">
				<div class="a_title1">{{list.title}}</div>
				<div class="a_title2">
					<span v-html="list.content"></span>
				</div>
			</div>
		</div>
		<Footer/>
	</div>
</template>

<script>
	import Footer from '@/components/footer'
	export default {
		components: {
			Footer
		},
		data() {
			return {
				id:'',
				list:{}
			}
		},
		activated() {
			this.id=this.$route.query.id
			this.$axios({
				url:'School/news_detail',
				method:'post',
				data:{
					id:this.id
				}
			}).then(res=>{
				this.list=res.data.data
			})
		},
		methods: {
			
		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
	body{
		background-color: #FFFFFF !important;
	}
	.a_content{
		width: 1200px;
		background-color: #FFFFFF;
		margin: auto;
		.a_title1{
			font-size: 24px;
			font-weight: 400;
			color: #333333;
			text-align: center;
			padding: 30px 0px;
		}
		.a_title2{
			padding: 0px 30px;
			box-sizing: border-box;
		}
	}
</style>
