<template>
	<div>
		<myNav/>
		<div class="content">

			<!-- <div v-if="myValue==1 && school==1">
				<div class="a_all">
					<div class="a_title1">首页-院校查询</div>
					<div class="a_title5" style="margin-top: 47px;">
						<div class="a_title6" style="margin-top:7px;">省份选择：</div>
						<div style="display: flex;flex-wrap: wrap;width: 1000px;">
							<div :class="isCheck==index ? 'a_title8' : 'a_title7'" v-for="(item,index) in 30" :key="index" @click="isOk(index)">北京</div>
						</div>
					</div>
					<div class="a_title5" style="margin-top: 27px;">
						<div class="a_title6" style="display: flex;align-items: center;">学历级别：</div>
						<div style="display: flex;flex-wrap: wrap;text-align: left;width: 1000px;">
							<div :class="isCheck==index ? 'a_title8' : 'a_title7'" v-for="(item,index) in 2" :key="index" @click="isOk(index)">本科</div>
						</div>
					</div>
					<div class="a_title5" style="margin-top: 27px;">
						<div class="a_title6" style="margin-top:7px;">高校级别：</div>
						<div style="display: flex;flex-wrap: wrap;width: 1000px;">
							<div :class="isCheck==index ? 'a_title8' : 'a_title7'" v-for="(item,index) in 6" :key="index" @click="isOk(index)">双高计划</div>
						</div>
					</div>
					<div class="a_title5" style="margin-top: 27px;">
						<div class="a_title6" style="margin-top:7px;">办学性质：</div>
						<div style="display: flex;flex-wrap: wrap;width: 1000px;">
							<div :class="isCheck==index ? 'a_title8' : 'a_title7'" v-for="(item,index) in 6" :key="index" @click="isOk(index)">公办</div>
						</div>
					</div>
					<div class="a_title5" style="margin-top: 27px;">
						<div class="a_title6" style="margin-top:7px;">高校隶属：</div>
						<div style="display: flex;flex-wrap: wrap;width: 1000px;">
							<div :class="isCheck==index ? 'a_title8' : 'a_title7'" v-for="(item,index) in 6" :key="index" @click="isOk(index)">教育部</div>
						</div>
					</div>
					<div class="a_title5" style="margin-top: 27px;">
						<div class="a_title6" style="margin-top:7px;">学校类型：</div>
						<div style="display: flex;flex-wrap: wrap;width: 1000px;">
							<div :class="isCheck==index ? 'a_title8' : 'a_title7'" v-for="(item,index) in 6" :key="index" @click="isOk(index)">农业</div>
						</div>
					</div>
				</div>
				<div class="b_all">
					<span class="b_title1">已为你找到 </span>
					<span class="b_title2">2660</span>
					<span class="b_title1">所大学</span>
				</div>
				<div class="c_content" v-for="(item,index) in 6" :key="index" @click="detail()">
					<div class="c_all">
						<div><img class="c_img" src="@/assets/tu3.png" alt=""></div>
						<div class="c_title1">
							<div class="c_title2">北京大学</div>
							<div class="c_title3">北京市</div>
							<div class="c_title4">
								<div class="c_title5">公办</div>
								<div class="c_title6">985</div>
								<div class="c_title7">211</div>
								<div class="c_title8">双一流</div>
							</div>
						</div>
					</div>
					<div class="c_title9">
						<i class="iconfont icon-mn_paiming_fill"></i>
						<span class="c_title10">排名1</span>
					</div>
				</div>
				<div class="search_all"><div class="search">查看更多</div></div>
			</div> -->

			<div class="g_content">
				<div class="g_all">
					<div style="text-align: left;"><img class="g_img" :src="detail.logo" alt=""></div>
					<div class="g_title1">
						<div class="g_title2">
							<div class="g_title3">{{detail.title}}</div>
							<!-- <div class="g_title4">已关注</div> -->
						</div>
						<div class="g_title5">
							<div class="g_title6">博士点：{{detail.doc_point ? detail.doc_point : '-'}}</div>
							<div class="g_title6">硕士点：{{detail.master_program ? detail.master_program : '-'}}</div>
						</div>
					</div>
				</div>
				<div class="g_title7"></div>
				<div class="gg_content">
					<div class="gg_title1">基本信息</div>
					<div class="gg_all">
						<div class="gg_title2">
							<div class="gg_title3">国标代码：{{detail.postcode}}</div>
							<div class="gg_title3">所在省市：{{detail.province_name}}</div>
							<div class="gg_title3">隶属部门：{{detail.subordinate}}</div>
							<div class="gg_title3">办学性质：{{detail.is_pubilc == 1 ? '公办' : '民办'}}</div>
							<div class="gg_title3">院校层次：{{detail.school_type}}</div>
						</div>
						<div class="gg_title2">
							<div class="gg_title3">院校类型：{{detail.type}}</div>
							<div class="gg_title3">招生电话：{{detail.telephone}}</div>
							<div class="gg_title3">院校地址：{{detail.address}}</div>
							<div class="gg_title3">招生网址：{{detail.web_url}}</div>
						</div>
					</div>
				</div>
				<div class="g_content1">
					<div @click="xuanZe(index)" :class="index1==index ? 'g_next1' : 'g_next2'" v-for="(item,index) in list2" :key="index">{{item.name}}</div>
				</div>
				<div class="g_title8"></div>
				<div class="g_title9">
					<div class="g_title10">院校简介</div>
					<span v-html="detail.brief" class="g_title11"></span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		components: {},
		data() {
			return {
				index1:0,
				school:1,
				currentPage:1,
				isCheck:0,
				isCheck1:1,
				list1:[{title:'哲学'},{title:'经济学'},{title:'法学'},{title:'教育学'},{title:'文学'},{title:'工学'},{title:'农学'},{title:'医学'},{title:'军事学'},{title:'管理学'},{title:'艺术学'},{title:'其他'}],
				list2:[{name:'学校概括'},{name:'录取信息'},{name:'招生计划'},{name:'选考要求'},{name:'简章查询'},{name:'高校就业'}],
				// 学校列表 - 详情
				province_list: [],
				searchCondition: {}, // 筛选条件
				college: {list: [],current: 1,total: 0,detail: {}}, // 学校列表
				filter: {
					school_type: '',
					is_pubilc: '',
					dual_class: '',
					f211: '',
					f985: '',
					type: ''
				},
				// 批次线
				batch: {},
				batchData: {
					year: '',
					province_id: ''
				},
				batchList: {},

				// 专业
				group: {
					list: [],
					type: 1
				}
			}
		},
		activated(){
			this.filter.token=this.$store.state.userData.data.token
			// 获取筛选列表
			this.$axios({
				url:'/School/searchCondition',
				method: 'POST',
			}).then(res=>{
				this.searchCondition = res.data.data
			})
			this.getList()
			this.$axios({ // 批次线
				url: '/batch/search',
				method: 'POST'
			}).then(res => {
				this.batch = res.data.data
				this.batchData.province_id = res.data.data.pro[0].id
				this.batchData.year = res.data.data.year[0]
				this.getBatch()
			})
			this.majorGroup()
		},
		methods: {
			getList (k = '',v = '') {
				if (this.filter[k] == v) {
					this.filter[k] = ''
				} else {
					this.filter[k] = v
				}
				Object.assign(this.filter,{page: this.current})
				this.$axios({
					url:'/school/index',
					method: 'POST',
					data: this.filter
				}).then(res=>{
					if (k) this.list = [],this.current = 1,this.total = ''
					if (res.data.data.current_page < res.data.data.last_page) {
						this.list = this.list.concat(res.data.data.data)
						this.current++
						if (!this.total) this.total = res.data.data.total
					}
					// console.log(this.list);
				})
			},
			proChange () {
				this.getBatch()
			},
			// 获取批次线
			getBatch (k='',v='') {
				if (k) {
					this.batchData[k] = v
				}
				this.$axios({
					url: 'batch',
					method: 'POST',
					data: this.batchData
				}).then(res => {
					this.batchList = res.data.data.list
				})
			},
			majorGroup (i = 1) {
				this.group.type = i
				this.$axios({
					url: '/major/group',
					method: 'POST',
					data: {
						type: this.group.type
					}
				}).then(res => {
					this.group.list = res.data.data
				})
			},
			//年份选中状态
			isOk(e){
				this.isCheck=e
			},
			isLie(e){
				this.isCheck1=e
			},
			//改变当前页的方法
			currentChange(val){
				this.currentPage=val;
			},
			//学校详情
			detail(id){
				this.$axios({
					url: 'School/detail',
					method: 'POST',
					data: {school_id: id}
				}).then(res=>{
					this.detail = res.data.data.detail
				})
			},
			xuanZe(e){
				this.index1=e
			}
		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
	.content {
		.top_img{
			width: 1200px;
			height: 500px;
		}
		.img_title1{
			background-color: #FFFFFF;
			padding: 25px 40px 0px 40px;
			box-sizing: border-box;
			display: flex;
			align-items: center;
			.img_title2{
				width: 970px;
				height: 55px;
				background-color: #F2F8FF;
				.img_title3{
					width: 1140px;
					height: 50px;
					background-color: #F2F8FF;
				}
			}
			.img_title4{
				display: flex;
				align-items: center;
				width: 152px;
				height: 55px;
				background: #3B68DF;
				justify-content: center;
				.iconfont{
					color: #FFFFFF;
				}
				.img_title5{
					font-size:16px;
					font-weight: 400;
					color: #FFFFFF;
					margin-left: 8px;
				}
			}
		}
		.a_all {
			padding: 35px 19px;
			box-sizing: border-box;
			background-color: #FFFFFF;

			.a_title1 {
				font-size: 24px;
				font-weight: 400;
				color: #666666;
				text-align: left;
			}

			.a_title2 {
				display: flex;
				align-items: center;

				.a_title3 {
					margin: 47px 0px;
					font-size: 22px;
					font-weight: 400;
					color: #333333;
				}

				.a_title4 {
					font-size: 22px;
					font-family: PingFang SC;
					font-weight: 400;
					line-height: 43px;
					color: #999999;
					margin-left: 47px;
				}
			}

			.a_title5 {
				font-size: 22px;
				font-weight: 400;
				color: #333333;
				display: flex;
				flex-wrap: wrap;
				.a_title6{
					font-size: 22px;
					font-weight: 400;
					color: #333333;
					text-align: left;
					display: flex;
					margin-top: 7px;
				}
				.a_title7{
					font-size: 22px;
					font-family: PingFang SC;
					font-weight: 400;
					line-height: 43px;
					color: #999999;
					margin-left: 47px;
				}
				.a_title8{
					font-size: 22px;
					font-family: PingFang SC;
					font-weight: 400;
					line-height: 43px;
					color:#337CFF;
					margin-left: 47px;
				}
			}
		}
		.b_all{
			display: flex;
			align-items: center;
			margin: 58px 0px 23px 34px;
			.b_title1{
				font-size:24px;
				font-weight: 400;
				color: #666666;
			}
			.b_title2{
				font-size:24px;
				font-weight: 400;
				color:#3B68DF;
			}
		}
		.c_content{
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 35px 36px;
			box-sizing: border-box;
			background-color: #FFFFFF;
			border-bottom: 1px solid #F5F5F5;
			.c_all{
				display: flex;
				align-items: center;
				.c_img{
					width: 110px;
					height: 110px;
					border-radius: 50%;
					margin-right: 23px;
				}
				.c_title1{
					text-align: left;
					.c_title2{
						font-size:20px;
						font-weight: 400;
						color: #333333;
					}
					.c_title3{
						margin: 10px 0px;
						font-size: 18px;
						font-weight: 400;
						color: #999999;
					}
					.c_title4{
						display: flex;
						align-items: center;
						.c_title5{
							width: 41px;
							height: 24px;
							background: #F0F5FF;
							opacity: 1;
							border-radius: 5px;
							font-size: 14px;
							font-weight: 400;
							color: #337CFF;
							margin-right: 12px;
							display: flex;
							justify-content: center;
							align-items: center;
						}
						.c_title6{
							width: 37px;
							height: 24px;
							background: #FFF1F0;
							border-radius: 5px;
							font-size: 14px;
							font-weight: 400;
							color: #FF4D4F;
							margin-right: 12px;
							display: flex;
							justify-content: center;
							align-items: center;
						}
						.c_title7{
							width: 33px;
							height: 24px;
							background: #DDF7E2;
							border-radius: 5px;
							font-size: 14px;
							font-weight: 400;
							color:#3DB159;
							margin-right: 12px;
							display: flex;
							justify-content: center;
							align-items: center;
						}
						.c_title8{
							width: 55px;
							height: 24px;
							background: #F0F5FF;
							border-radius: 5px;
							font-size: 14px;
							font-weight: 400;
							color:#3B68DF;
							margin-right: 12px;
							display: flex;
							justify-content: center;
							align-items: center;
						}
					}
				}
			}
			.c_title9{
				.iconfont{
					color: #FECB1E;
					font-size: 30px;
				}
				.c_title10{
					font-size: 30px;
					font-weight: 400;
					margin-left: 9px;
					color: #FECB1E;
				}
			}
		}
		.search_all{
			height: 179px;
			display: flex;
			justify-content: center;
			align-items: center;
			background-color: #FFFFFF;
		.search{
			width: 350px;
			height: 83px;
			background: #337CFF;
			opacity: 1;
			border-radius: 7px;
			font-size:28px;
			font-weight: 400;
			color: #FFFFFF;
			display: flex;
			justify-content: center;
			align-items: center;
		}
		}
		.a_content{
			display: flex;
			// margin-top: 50px;
			height: 100px;
			align-items: center;
			.a_title9{
				font-size:24px;
				font-weight: 400;
				color: #666666;
				display: flex;
				justify-content: center;
				align-items: center;
				height: 100px;
			}
		}
		.d_content{
			width: 1200px;
			background: #F0F5FF;
			border: 5px solid #407EF6;
			padding: 35px 45px;
			box-sizing: border-box;
			margin: 35px 0px;
			.d_title1{
				font-size: 24px;
				font-weight: 500;
				color: #337CFF;
				text-align: left;
			}
			.d_title2{
				font-size: 20px;
				font-family: PingFang SC;
				font-weight: 400;
				line-height: 35px;
				color: #666666;
				margin-top: 20px;
				text-align: left;
			}
		}
		.f_content{
			width: 1200px;
			padding: 35px 41px;
			background: #FFFFFF;
			box-sizing: border-box;
			font-size:20px;
			font-weight: 400;
			color: #666666;
			text-align: left;
		}
		.f_title1{
			display: flex;
			align-items: center;
			width: 1200px;
			height: 73px;
			background: #F2F8FF;
			margin-top: 35px;
			.f_title5{
				font-size:20px;
				font-weight: 400;
				margin:0px 58px 0px 49px;
			}
			.f_title6{
				font-size: 18px;
				font-weight: 400;
				color: #337CFF;
			}
			// .f_title7{
			// 	.f_title8{

			// 	}
			// }
			.f_title2{
				width: 289px;
				height: 60px;
				background: #337CFF;
				font-size:20px;
				font-weight: 400;
				color: #FFFFFF;
				display: flex;
				justify-content: center;
				align-items: center;
			}
			.f_title3{
				width: 289px;
				height: 60px;
				background: #F5F5F5;
				font-size:20px;
				font-weight: 400;
				display: flex;
				justify-content: center;
				align-items: center;
			}
		}
		.f_all{
			display: flex;
			flex-wrap: wrap;
			padding: 35px;
			box-sizing: border-box;
			background: #FFFFFF;
			.f_title4{
				padding: 12px 47px;
				box-sizing: border-box;
				background: #F3F5F6;
				font-size: 18px;
				font-weight: 400;
				color: #999999;
				margin: 0px 35px 23px 0px;
			}
		}
		.g_content{
			background-color: #FFFFFF;
			width: 1200px;
			margin: auto;
			padding: 30px 0px;
			box-sizing: border-box;
			.g_all{
				display: flex;
				align-items: center;
				.g_img{
					width: 100px;
					height: 100px;
					margin:0px 20px 0px 30px;
					border-radius: 50%;
				}
				.g_title1{
					.g_title2{
						display: flex;
						align-items: center;
						.g_title3{
							font-size:24px;
							font-weight: 400;
							color: #333333;
							margin-right: 20px;
						}
						.g_title4{
							width: 47px;
							height: 21px;
							background: #337CFF;
							border-radius: 10px;
							font-size: 12px;
							font-weight: 400;
							color: #FFFFFF;
							display: flex;
							align-items: center;
							justify-content: center;
						}
					}
					.g_title5{
						display: flex;
						align-items: center;
						margin-top: 26px;
						.g_title6{
							font-size: 16px;
							font-weight: 400;
							color: #999999;
							margin-right: 30px;
						}
					}
				}
			}
			.g_title7{
				width: 1140px;
				height: 0px;
				border: 2px solid #CCCCCC;
				margin:22px auto;
			}
			.gg_content{
				padding: 0px 30px;
				box-sizing: border-box;
				background-color: #FFFFFF;
				.gg_title1{
					font-size: 20px;
					font-weight: 400;
					color: #3B68DF;
					margin-bottom: 10px;
					text-align: left;
				}
				.gg_all{
					display: flex;
					.gg_title2{
						margin-right: 108px;
						text-align: left;
						.gg_title3{
							font-size: 16px;
							font-weight: 400;
							color: #666666;
							margin-bottom: 10px;
						}
					}
				}
			}
			.g_content1{
				display: flex;
				justify-content:space-between;
				.g_next1{
					width: 200px;
					height: 55px;
					background: #F2F8FF;
					font-size: 20px;
					font-weight: 400;
					color: #337CFF;
					display: flex;
					align-items: center;
					justify-content: center;
				}
				.g_next2{
					width: 200px;
					height: 55px;
					font-size: 20px;
					font-weight: 400;
					color:#333333;
					display: flex;
					align-items: center;
					justify-content: center;
				}
			}
			.g_title8{
				width: 1200px;
				height: 2px;
				background: #337CFF;
			}
			.g_title9{
				.g_title10{
					font-size: 24px;
					font-weight: 400;
					color: #333333;
					margin: 30px 0px 20px 30px;
					text-align: left;
				}
				.g_title11{
					font-size: 20px;
					font-weight: 400;
					color: #333333;
					margin:0px 30px 0px 30px;
				}
			}
		}
	}
</style>
