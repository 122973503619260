<template>
<div>
<myNav />
<loginPop/>
<div class="content" :style="show ? {'background' : 'rgba(0,0,0,.1)'} : ''">
	<div class="pop" v-show="show">
		<div class="p_title1">请选择你的选科</div>
		<div class="p_title2">科目选择（6选3）</div>
		<div class="p_all">
			<div @click="course(index)" :style="!item.state ? {'background':'#F5F5F5','color':'#707070'} : ''" v-for="(item,index) in courseData" :key="index" class="p_title3">{{item.name}}</div>
		</div>
		<div @click="submit()" class="p_title4" :style="num>=3 ? '' : {'background':'#F5F5F5','color':'#707070'}">已选{{num}}，查看报考专业</div>
	</div>
<div class="a_content" @click="disappear()" >
	<div class="a_all">
		<div class="a_title1">欢迎您使用</div>
		<div class="a_title2">高考填报模拟系统</div>
		<div class="a_title3">领先的大数据分析技术，整合历年考生录取情况，通过院校优先、专业优先、一键填报三大方向助考生快速锁定意向大学。</div>
		<div class="a_title3">流程：考生输入分数、科目—选择批次—只能推荐冲、稳、 保三种风险的院校和专业—选择意向志愿，生成模拟志愿表</div>
	</div>
	<div class="a_title4">
	<div class="a_title5">
		<div class="a_title6">
			<div class="a_title7">
				<div class="a_title8">新高考模式</div>
				<div class="a_title9">
					<div>地区</div>
					<el-form ref="form" class="form" label-position="left">
					<el-form-item>
					<el-select v-model="form.name" placeholder="请选择地区" style="width: 100%" @change="genderChange">
						<el-option v-for="(item,index) in list" :key="index" :label="item.title" :value="item"></el-option>
					</el-select>
					</el-form-item>
					</el-form>
					<div class="a_title10" @click.stop="searchCourse()">查询选科数据</div>
				</div>
				<div class="b_content">
					<div class="b_title1" @click="searchCollege()">
						<div class="b_title2">查大学</div>
						<div class="b_title2">看选科要求</div>
					</div>
					<div class="b_title1" @click="searchFesic()" style="background-color:#FFD76A;">
						<div class="b_title2">查专业</div>
						<div class="b_title2">看选科要求</div>
					</div>
					<div class="b_title1"  style="background-color:#FFAF6A;">
						<div class="b_title2">查选科</div>
						<div class="b_title2">看选科要求</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	</div>
</div>
</div>
<Footer />
</div>
</template>

<script>
	import Footer from '@/components/footer.vue'
	export default {
		components: {
			Footer
		},
		data() {
			return {
				form: {
					name: ''
				},
				num:0,
				list:[],
				show:false,
				courseData:[{name:'物理',state:false},
						{name:'生物',state:false},
						{name:'政治',state:false},
						{name:'生物',state:false},
						{name:'历史',state:false},
						{name:'地理',state:false}]
			}
		},
		activated() {
			this.dataAll()
		},
		methods: {
			//跳转查看大学页面
			searchCollege(){
				this.$router.push({path:'/new/searchPro'})
			},
			searchFesic(){
				this.$router.push({path:'/new/searchFesic'})
			},
			//选择科目
			course(e){
				if (this.courseData[e].state){
					this.courseData[e].state = false
					this.num--
				}else {
					this.courseData[e].state = true
					this.num++
				}
			},
			//提交查询选科数据
			submit(){
				if(this.num>=3){
					let data=[]
					this.courseData.forEach(item=>{
						if(item.state){
							data.push(item.name)
						}
					})
					this.show=false
					this.$router.push({path:'/newGaokao/newIndex',query:{data:data}})
				}else{
					this.$message({
						message:'至少选择三科',
						type:'warning'
					})
				}
			},
			//弹出框消失
			disappear(){
				this.show=false
			},
			//获取所有数据
			dataAll(){
				this.$axios({
					url:'NewCneProvince/getProvince',
					method:'post'
				}).then(res=>{
					this.list=res.data.data
				})
			},
			//选中市级
			genderChange(e){
				this.form.name=e.title
			},
			//查询选科数据
			searchCourse(){
				this.show=true
				//this.$router.push({path:'/mySearch/searchPro'})
			}
		}
	}
</script>
<style lang="less" scoped>
.content {
		position: relative;
		width: 100%;
		height: 100%;
		z-index: 9;
		display: flex;
		align-items: center;
		justify-content: center;
	.pop{
		position: absolute;
		border-radius: 20px;
		border: 1px solid #F5F5F5;
		width: 670px;
		height: 470px;
		background: #FFFFFF;
		z-index: 9;
		padding: 10px 30px 40px 30px;
		box-sizing: border-box;
		.p_title1{
		font-size: 20px;
		font-weight: 400;
		color: #333333;	
		text-align: center;
		}
		.p_title2{
		font-size: 18px;
		font-weight: 400;
		color: #333333;
		margin-top: 20px;
		}
		.p_all{
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		justify-content: center;
		margin-top: 80px;
		.p_title3{
		width: 80px;
		height: 40px;
		background: #337CFF;
		border-radius: 4px;
		font-size: 14px;
		font-weight: 400;
		color: #FFFFFF;
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0px 80px 50px 0px;
		}
		}
		.p_title4{
		width: 300px;
		height: 40px;
		background: #337CFF;
		border-radius: 4px;
		font-size: 14px;
		font-weight: 400;
		color: #FFFFFF;
		display: flex;
		align-items: center;
		justify-content: center;
		margin:30px auto;
		}
	}
	.a_content {
		display: flex;
		justify-content: space-between;
		width: 1200px;
		height: 839px;
		background: linear-gradient(270deg, #217CD8 0%, #3030B4 100%);
		display: flex;
		align-items: center;
		padding: 0px 100px;
		box-sizing: border-box;
		margin-bottom: 100px;
		margin-top: 1px;
		.a_all {
			width: 383px;
			.a_title1 {
				font-size: 20px;
				font-weight: 400;
				color: #FEA52D;
			}
			.a_title2 {
				font-size: 18px;
				font-weight: 400;
				color: #FEA52D;
				margin: 10px 0px 0px 0px;
			}
			.a_title3 {
				font-size: 16px;
				font-weight: 400;
				color: #FFFFFF;
				margin-top: 30px;
				text-align: left;
			}
		}
		.a_title4 {
			width: 532px;
			height: 422px;
			display: flex;
			align-items: center;
			justify-content: center;
			.a_title5 {
			width: 592px;
			height: 380px;
			background: rgba(255, 255, 255, 0.3);
			border-radius: 10px;
			display: flex;
			align-items: center;
			justify-content: center;
			.a_title6 {
			width: 592px;
			height: 332px;
			background: rgba(255, 255, 255, 0.3);
			border-radius: 10px;
			display: flex;
			align-items: center;
			justify-content: center;
			.a_title7 {
			align-items: center;
			width: 482px;
			height: 422px;
			background: #FFFFFF;
			border-radius: 10px;
			padding: 30px 0px;
			box-sizing: border-box;
			justify-content: center;
			.a_title8 {
			width: 182px;
			padding-bottom: 15px;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 24px;
			font-weight: 400;
			color: #3B68DF;
			margin: auto;
			border-bottom: 2px solid #3B68DF;
			}
			.a_title9{
				display: flex;
				align-items: center;
				justify-content: center;
					margin-top: 50px;
				.form{
				width:120px;
				margin: 20px 40px 0px 20px;
				}
				.a_title10{
				width: 230px;
				height:35px;
				background: #3B68DF;
				border-radius: 10px;
				font-size: 16px;
				font-weight: 400;
				color: #FFFFFF;
				display: flex;
				align-items: center;
				justify-content: center;
				}
			}
			.b_content{
				display: flex;
				align-items: center;
				justify-content:space-around;
				margin-top: 40px;
				.b_title1{
				width: 116px;
				height: 140px;
				background: #9E6AFF;
				border-radius: 10px;
				display: flex;
				align-items: center;
				flex-direction: column;
				justify-content: space-around;
				.b_title2{
				font-size: 16px;
				font-weight: 400;
				color: #FFFFFF;
				}
				}
			}
			}
			}
			}
		}
	}
}
</style>
