<template>
<div>
	<myNav/>
		<div class="r_content">
			<div class="a_title1">高考要闻>新闻详情</div>
			<div class="a_title2">{{list.length>0 ? list[0].title : ''}}</div>
			<div class="a_title3">
				<span class="a_title4">来源：{{list.length>0 ? list[0].source : ''}}</span>
				<span class="a_title4" style="margin-left: 40px;">{{list.length>0 ? list[0].create_time : ''}}</span>
			</div>
			<div class="a_title6">
				<span v-html="list.length>0 ? list[0].content : ''" class="a_title7"></span>
			</div>
		</div>
	<Footer/>
</div>
</template>
<script>
	import Footer from '@/components/footer'
export default {
components: {
	Footer
},
data(){
	return {
		id:"",
		list:[]
	};
	},
	activated() {
		this.id=this.$route.query.id
		this.dataAll(this.id)
	},
	methods:{
		//获取新闻详情
		dataAll(e){
			this.$axios({
				url:'News/NewsContent',
				method:'post',
				data:{id:e}
			}).then(res=>{
				this.list=res.data.data
			})
		}
	},
}

</script>

<style lang="less" scoped>
	.r_content{
		width: 1200px;
		background-color: #FFFFFF;
		margin: auto;
		padding: 20px 30px;
		box-sizing: border-box;
		.a_title1{
			font-size: 18px;
			font-weight: 600;
			color: #333333;
		}
		.a_title2{
			font-size: 16px;
			font-weight: 500;
			text-align: center;
		}
		.a_title3{
			display: flex;
			justify-content: center;
			margin-top: 20px;
			.a_title4{
				font-size: 16px;
				font-weight: 400;
				color: #999999;
			}
		}
	}
</style>
