<template>
	<div>
		<myNav/>
		<login-pop/>
		<vipPop/>
		<div class="content">
			<div class="h_title1">测评介绍</div>
			<div class="h_content">
				<div class="h_title2">
					<span class="h_title3">{{current}}/{{list.length}}</span>
					<span class="h_title4">{{list.length>0 ? list[current].title==null ? '-' : list[current].title : '-'}}</span>
				</div>
				<div @click="kai(index)" v-show="list[current].type == 1" v-for="(item,index) in list[current].answer" :key="index" :class="check==index ? 'h_title5' : 'h_title6'">{{item}}</div>
				<div class="a_4" v-if="list[current].type == 3">
					答：<input class="b_1" placeholder-class="b_2" type="text" v-model="value" placeholder="请输入答案"/>
				</div>
				<div class="all">
					<div class="submit" @click="previous()">上一题</div>
					<!-- <div class="submitNext" @click="next()">下一题</div> -->
				</div>
			</div>
		</div>
		<Footer/>
	</div>
</template>

<script>
	import Footer from '@/components/footer'
	export default {
		components: {
			Footer
		},
		props: ['myValue'],
		data() {
			return {
				yesData:[{name:'是'},{name:'否'}],
				check:null,
				isShow: true,
				list: [{answer:[{
					title:'',state:''
				}]}], // 题
				current: 0, // 当前答题
				result: [] ,// 答案
				value: null,
				opt: {},
				chartData: {
					series: [
						{
							name: '0',
							data: 0,
							color: '#65B2F5'
						},
					]
				},
				myList:[{title:'',state:''}]
			}
		},
		activated(){
			if(!this.$store.state.userData.token) return
			this.current=0
			this.$axios({
				url:'review_api/topic',
				methods:'post',
				data:{
					user_id:this.$store.state.userData.user_id,
					col_id:this.$route.query.id
				}
			}).then(res=>{
				this.list=res.data.list
			})
		},
		methods: {
			
			kai(e){
				this.check=e
				this.value=e
				this.next()
			},
			isCheck(e){
				this.check=e
			},
			// 上一题
			previous() {
				if (this.current > 0){
					this.result.pop()
					this.current -= 1
					this.chartData.series[0] = {
						data: this.current / this.list.length
					}
					this.chartData.title = {
						name: this.current + '/' + this.list.length,
					}
				}else {
					this.$message('请正常答题')
				}
			},
			next(){
				// 测试题完成
				if (!this.value && this.value != 0){
					return this.$message('请答题')
				} 
				this.result.push(this.value)
				this.value = null
				if ((this.current + 1) == this.list.length){
					this.$store.commit("testData",this.result)
					this.$router.push({path:'/ceshi/resulte',query:{id:this.$route.query.id,nid:0}})
					return
				}
				this.current++
				this.chartData.series[0] = {
					name: this.current + '/',
					data: this.current / this.list.length,
				}
				this.chartData.title = {
					name: this.current + '/' + this.list.length,
				}
				this.check=null
			}
		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
	.content {
		.h_title1{
			width: 1200px;
			height: 55px;
			background: #F2F8FF;
			font-size:24px;
			font-weight: 400;
			color: #333333;
			display: flex;
			align-items: center;
			padding-left: 49px;
			box-sizing: border-box;
			margin-top: 35px;
		}
		.h_content{
			width:1200px;
			height: 1029px;
			background: #FFFFFF;
			padding: 26px 51px;
			box-sizing: border-box;
			.h_title2{
				display: flex;
				align-items: center;
				margin-bottom:20px;
				.h_title3{	
				font-size: 17px;
				font-weight: 400;
				color: #707070;
				margin-right: 43px;
				}
				.h_title4{	
				font-size: 17px;
				font-weight: 400;
				color: #707070;
				margin-right: 43px;
				}

			}
			.h_title6:hover{
				background-color:#F58235;
				color: #FFFFFF;
			}
			.h_title5{
				width: 1100px;
				height: 40px;
				background: #337CFF;
				border-radius: 4px;
				display: flex;
				align-items: center;
				padding-left: 20px;
				box-sizing: border-box;
				font-size: 16px;
				font-weight: 400;
				color: #FFFFFF;
				margin-bottom: 20px;
			}
			.h_title6{
				width: 1100px;
				height: 40px;
				background:#F5F5F5;
				border-radius: 4px;
				display: flex;
				align-items: center;
				padding-left: 20px;
				box-sizing: border-box;
				font-size: 16px;
				font-weight: 400;
				color: #333333;
				margin-bottom: 20px;
			}
			.a_4{
				
				.b_1{
					width: 400px;
					height: 40px;
					background-color: #F5F5F5;
					padding-left: 20px;
					outline: none;
					border: none;
				}
			}
			.all{
				display: flex;
				align-items: center;
				margin: 100px auto;
				justify-content: center;
				.submit:hover{
					color:#FFFFFF;
					background-color: #337CFF;
				}
				.submitNext:hover{
					color:#FFFFFF;
					background-color: #F5F5F5;
				}
				.submitNext{
						font-size: 16px;
						font-weight: 400;
						color: #FFFFFF;
						width: 140px;
						height: 50px;
						background: #337CFF;
						border-radius: 4px;
						display: flex;
						align-items: center;
						justify-content: center;
						margin-left:100px;
					}
					.submit{
					font-size: 16px;
					font-weight: 400;
					color: #F58235;
					width: 140px;
					height: 50px;
					background: #F5F5F5;
					border-radius: 4px;
					display: flex;
					align-items: center;
					justify-content: center;
				}
			}
		}
	}
</style>
