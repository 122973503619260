<template>
  <div style="margin: auto;">
	<myNav/>
    <div class="b_content">
      <div class="a_content" :style="{'background': imgs[bannerIndex] ? imgs[bannerIndex].coloe : '' }">
        <div style="width:1200px;margin:auto;display:flex;">
			<div class="a_left">
          <el-carousel class="a_img" style="width:100%;" :interval="3000" arrow="never" @change="carChange">
            <el-carousel-item v-for="(items, index) in imgs" :key="index">
              <!-- <img :src="items.banner_img" style="width:1200px;height:549px;object-fit:cover;"/> -->
				<el-image style="width:900px;height:471px;" :src="items.banner_img"></el-image>
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="a_right">
          <div class="a_title1">2022志愿填报</div>
          <div class="a_title2">
            <div class="a_title3">科目：</div>
			<div class="g_content">
				<div @click="subjects(index)" :class="item.state ? 'g_title1' : 'g_title2'" v-for="(item,index) in courseData" :key="index">{{item.name}}</div>
			</div>
          </div>
		<div v-if="this.$store.state.userData.userInfo&&this.$store.state.userData.userInfo.province==37" class="a_title5" style="margin-bottom:10px;align-items:flex-start;">
			<div class="a_title6">专业：</div>
			<div>
			<el-form ref="form"  label-position="left">
				<el-form-item label="" style="width:220px;">
					<el-select v-model="form.major_title" placeholder="请选择专业" style="width: 100%">
						<el-option :label="item.cat_id" :value="item.cat_id" v-for="(item,index) in myMajor" :key="index"></el-option>
					</el-select>
				</el-form-item>
			</el-form>
			</div>
		</div>
          <div class="a_title5">
            <div class="a_title6">分数：</div>
            <div>
              <input v-model="form.score" type="text" placeholder="请输入您的分数" class="a_input" />
            </div>
          </div>
          <div class="a_title5">
            <div class="a_title6">位次：</div>
            <div>
              <input v-model="form.rank" type="text" placeholder="请输入您的位次" class="a_input" />
            </div>
          </div>
          <div class="a_title7" @click="fill()">志愿填报</div>
        </div>
		</div>
      </div>
      <div class="c_content">
        <div class="c_title1">新高考</div>
        <div class="c_title2">
          <div class="c_all" @click="selectionCourse(1)">
            <!-- <div class="vip">VIP</div> -->
            <img class="c_img" src="@/assets/index1.png" alt="" />
            <!-- <div class="c_title3">选科分析</div> -->
          </div>
          <div class="c_all" @click="selectionCourse(2)">
            <!-- <div class="vip">VIP</div> -->
            <img class="c_img" src="@/assets/index2.png" alt="" />
            <!-- <div class="c_title3">选科分析</div> -->
          </div>
          <div class="c_all" @click="selectionCourse(3)">
            <!-- <div class="vip">VIP</div> -->
            <img class="c_img" src="@/assets/index3.png" alt="" />
            <!-- <div class="c_title3">选科分析</div> -->
          </div>
          <div class="c_all" @click="selectionCourse(4)">
            <!-- <div class="vip">VIP</div> -->
            <img class="c_img" src="@/assets/index4.png" alt="" />
            <!-- <div class="c_title3">选科分析</div> -->
          </div>
        </div>
      </div>
      <div class="c_content">
        <div class="c_title4">
          <span class="c_title5">推荐大学</span>
          <span class="c_title6" @click="more()">查看更多<i style="margin-left: 10px;" class="iconfont icon-icon-xian-11"></i></span>
        </div>
        <div class="c_all">
          <div
            class="c_title7"
            v-for="(item, index) in listData1"
            :key="index"
            v-show="index < 4"
			@click="collegeDetail(item)"
          >
            <div class="c_title8">
              <img class="c_logo" :src="item.logo" alt="" />
            </div>
            <div class="c_title9">{{ item.title }}</div>
            <div class="c_title10">{{ item.province_name }}</div>
            <div class="c_title11">
              <div class="c_title12" v-show="item.f985 == 1">985</div>
              <div class="c_title13" v-show="item.f211 == 1">211</div>
              <div class="c_title14" v-show="item.dual_class == 1">双一流</div>
            </div>
          </div>
        </div>
      </div>
      <div class="d_content" style="width:1200px;margin:auto">
        <div class="dd_all" style="margin-right: 85px" @click="yuanList()">
          <div class="d_all">
            <div class="d_title1">VIP</div>
            <i class="iconfont icon-yuanxiao"></i>
          </div>
          <div class="d_title2">院校查询</div>
        </div>
        <div class="dd_all" style="margin-right: 85px" @click="zhuanye()">
          <div class="d_all">
            <div class="d_title1">VIP</div>
            <i class="iconfont icon-zhuanye"></i>
          </div>
          <div class="d_title2">专业查询</div>
        </div>
        <div class="dd_all" style="margin-right: 85px" @click="control()">
          <div class="d_all">
            <div class="d_title1">VIP</div>
            <i class="iconfont icon-sheng"></i>
          </div>
          <div class="d_title2">省控线查询</div>
        </div>
        <!-- <div class="dd_all" >
          <div class="d_all">
            <div class="d_title1">VIP</div>
            <i class="iconfont icon-shuju4"></i>
          </div>
          <div class="d_title2">院校分数线</div>
        </div> -->
        <div class="dd_all" style="margin-right: 85px" @click="yuanxiao()">
          <div class="d_all">
            <div class="d_title1">VIP</div>
            <i class="iconfont icon-shuju3"></i>
          </div>
          <div class="d_title2">专业分数线</div>
        </div>
        <div class="dd_all" style="margin-right: 85px" @click="professional()">
          <div class="d_all">
            <div class="d_title1">VIP</div>
            <i class="iconfont icon-zhaoshengzige"></i>
          </div>
          <div class="d_title2">招生计划</div>
        </div>
       <!-- <div class="dd_all" style="margin-right: 85px">
          <div class="d_all">
            <div class="d_title1">VIP</div>
            <i class="iconfont icon-zongheyunyingfenxi"></i>
          </div>
          <div class="d_title2">去向分析</div>
        </div>
        <div class="dd_all" style="margin-right: 85px">
          <div class="d_all">
            <div class="d_title1">VIP</div>
            <i class="iconfont icon-xuexiaobeifen"></i>
          </div>
          <div class="d_title2">同专业院校</div>
        </div>
        <div class="dd_all" style="margin-right: 85px">
          <div class="d_all">
            <div class="d_title1">VIP</div>
            <i class="iconfont icon-ziyuan146"></i>
          </div>
          <div class="d_title2">强基计划</div>
        </div> -->
      </div>
   <!--   <div class="c_content" v-if="token">
        <div class="c_title1">1对1课程讲堂</div>
        <div style="display: flex; justify-content: space-between">
          <div class="cc_all" v-for="(item,i) in askexpertList" :key="i" v-show="i<3">
            <div class="c_images"><img class="cc_img" :src="item.avatar" alt="" /></div>
            <div class="cc_title1">{{item.username ? item.username : '匿名用户'}}</div>
            <div class="cc_title2">·适用学生·</div>
            <div class="cc_title2">·个人简介·</div>
			<div class="cc_title3" style="text-align: center">{{item.brief ? item.brief : '无相关内容'}}</div>
            <div class="cc_title6">立即咨询</div>
          </div>
        </div>
      </div> -->
      <div class="c_content" style="padding-top: 0px;">
        <div class="c_title4">
          <span class="c_title5">高考要闻</span>
          <span class="c_title6" @click="newsMore()">查看更多<i style="margin-left: 10px;" class="iconfont icon-icon-xian-11"></i></span>
        </div>
        <div class="d_content" style="padding-top: 0px;">
          <div
            class="d_all"
            v-for="(item, index) in newsData"
            :key="index"
            v-show="index < 9"
			@click="news(item)"
          >
			<el-image
			style="width: 190px; height: 130px;border-radius: 7px;margin-right: 20px;"
			:src="item.img"></el-image>
            <div class="d_title1">
              <div class="d_title2">{{ item.title }}</div>
              <div class="d_title3">
                <div class="d_title4">{{ item.source }}</div>
                <div class="d_title5">
                  <i class="iconfont icon-time"></i>
                  <span class="d_title6">{{ item.create_time }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
<!-- 	<div class="c_content">
		<div class="d_title7">联系商</div>
		<div class="d_title9">
			<div class="d_title10" v-for="(item,index) in merchant" :key="index">
				<div style="display: flex;align-items: center;">
					<div>{{item.name}}：</div>
					<div>{{item.telephone}}</div>
				</div>
			</div>
		</div>
	</div> -->
      <div class="c_content" style="padding-top:10px;">
        <div class="d_title7">友情链接</div>
        <div class="d_title9">
          <div class="d_title10" v-for="(item, index) in friendData" :key="index" @click="friendLian(item)">
            {{item.friendchain}}
          </div>
        </div>
      </div>
      <!-- 底部公共部分 -->
    </div>
    <div>
    </div>
    <Footer />
  </div>
</template>

<script>
	import Footer from '@/components/footer'
export default {
  components: {
	Footer
  },
  data() {
    return {
		list:{	fraction: '',
				subject: 1,
				selected_subject: {master: [],must:[],secondary:[]},
				exam_type: 1,
				province_id: 0},
		type: 1,
		form:{major_title:'',rank:"",score:'',data:[]},
		art:{},
		isNumber:0,
		listData1: [],
		newsData: [],
		bannerIndex: 1,
		imgs: [],
		askexpertList: [],
		token:'',
		courseData:[{name:'物理',state:false,key:8},{name:'生物',state:false,key:7},{name:'政治',state:false,key:3},
		{name:'化学',state:false,key:6},{name:'历史',state:false,key:5},{name:'地理',state:false,key:4}],
		friendData:[],
		myMajor:[],//专业数据，
		merchant:[]//联系上数据
    };
  },
	activated(){
		this.list={	fraction: '',subject: 1,selected_subject: {master: [],must:[],secondary:[]},exam_type: 1,province_id: 0}
		this.form={major_title:'',rank:"",score:'',data:[]}
		this.courseData=[{name:'物理',state:false,key:8},{name:'生物',state:false,key:7},{name:'政治',state:false,key:3},
		{name:'化学',state:false,key:6},{name:'历史',state:false,key:5},{name:'地理',state:false,key:4}]
		this.isNumber=0
		this.$store.commit('fillData',{})
		this.dataAll();
		if (this.$store.state.userData.token) this.token=this.$store.state.userData.token
	},
  methods: {
	//跳转更多大学
		more(){
			this.$router.push({path:'/Universities'})
		},
    dataAll() {
		//联系商
		this.$axios({
			url:'Provider/index',
			method:'post'
		}).then(res=>{
			this.merchant=res.data.data.data
		})
		//山东专业数据
		this.$axios({
			url:'IntelligentFilling/getMajor',
			method:'post',
			data:{
				province_id:37
			}
		}).then(res=>{
			this.myMajor=res.data.data
		})
		//友情链接
		this.$axios({
		url: "Friendchain/getFriain",
		method: "post",
		}).then((res) => {
			this.friendData=res.data.data
		});
      //推荐大学
      this.$axios({
        url: "school/index",
        method: "post",
      }).then((res) => {
        this.listData1 = res.data.data.data;
      });
      //大学要闻
      this.$axios({
        url: "News/NewsHomelist",
        method: "post",
        data: {
          page: 1,
        },
      }).then((res) => {
        this.newsData = res.data.data.data;
      });
      //banner图
		this.$axios({
			url: "Homepage/pcbanner_list",
			method: "post",
			data: {
				page: 1,
			},
		}).then((res) => {
			this.imgs=res.data.data
		})
      // 一对一课堂
      this.$axios({
        url: "Askexpert/AskexpertList",
        method: "post",
        data: {
          page: 1,
        },
      }).then((res) => {
        this.askexpertList=res.data.data.data
      })
		//获取member表里的数据
		if(this.$store.state.userData.token){
			this.$axios({
			url: "member/getMemberInfo",
			method: "post",
			data: {
			member_id:this.$store.state.userData.user_id
			},
			}).then((res) => {
				this.$store.commit('getMember',res.data.data)
			})
		}
		//选课筛选
		// this.list.exam_type = this.$store.state.userData.userInfo.exam_type
		// this.list.province = this.$store.state.userData.userInfo.province_id
		// this.$axios({
		// 	url: '/pro/school',
		// 	method: 'POST'
		// }).then(res => {
		// 	for(let i=0;i<res.data.data.length;i++){
		// 		if (res.data.data[i].id== this.$store.state.userData.userInfo.province_id) {
		// 			if (res.data.data[i].type) {
		// 				this.type = 1
		// 				this.selectList(res.data.data[i].type)
		// 			} else {
		// 				this.$message('您选择的高考城市未实行新高考')
		// 			}
		// 		}
		// 	}
		// })
    },
	//选择
	// selectList (type) {
	// 		this.$axios({
	// 			url: '/login/getArt'
	// 		}).then(res => {
	// 			console.log(res.data.data.select_type[type])
	// 			this.art =res.data.data.select_type[type]
	// 		})
	// 	},
	//智能填报
	fill(){
		if(!this.$store.state.userData.token) return this.$router.push({path:'/Login'})
		this.form.province=this.$store.state.memberData.province_id
		if(this.isNumber==3){
			let data=[]
			this.courseData.forEach(item=>{
				if(item.state){
					data.push(item)
				}
			})
			this.form.data=data
		}else{
			return this.$message({message:'至少选择三科',type:'warning'})
		}
		if(!this.form.score){
			return this.$message('请输入你的分数！')
		}else if(!this.form.rank){
			return this.$message('请输入你的位次！')
		}else{
			this.$axios({
				url:'IntelligentFilling/serachScore',
				method:'post',
				data:this.form
			}).then(res=>{
				if(res.data.status==200){
					this.$router.push({path:'/newGao'})
					this.$store.commit('fillData',this.form)
				}else if(res.data.status==999){
					this.$message({message:'请登录',type:'warning'})
					return this.$router.push({path:'/Login'})
				}else{
					return this.$message({message:res.data.msg,type:'warning'})
				}
			})
		}
	},
    //选中科目状态
    subjects(e) {
		if(this.courseData[e].state){
				this.courseData[e].state = false
				this.isNumber--
		}else{
			if(this.isNumber<3){
				this.courseData[e].state = true
				this.isNumber++
			}else{
				return this.$message({message:'只能选择三科',type:'warning'})
			}
		}
    },
	carChange(e) {
		this.bannerIndex=e
	},
	//学校详情
	scoll(){
	this.$router.push({path:'/Detail'})
	},
	//跳转大学详情页面
	collegeDetail(e){
		this.$store.commit('detail',{school_id:e.school_id,spare_type:e.spare_type})
		this.$router.push({path:'/Detail',query:{id:e.school_id}})
	},
	//新闻详情页面
	news(e){
		this.$router.push({path:'/news',query:{id:e.id}})
	},
	//跳转新闻首页页面
	newsMore(){
		this.$router.push({path:'/news/newsIndex'})
	},
	//跳转院校页面
	yuanxiao(){
		this.$router.push({path:'/volunteer/yLine'})
	},
	//跳转专业分数线页面
	professional(){
		this.$router.push({path:'/volunteer/admissions'})
	},
	//跳转院校查询页面
	yuanList(){
		this.$router.push({path:'/Universities'})
	},
	//跳转专业列表页面
	zhuanye(){
		this.$router.push({path:'/Zhuanye'})
	},
	//跳转省控线页面
	control(){
		this.$router.push({path:'/volunteer/professional'})
	},
	//跳转选课分析界面
	selectionCourse(e){
		if(e==1) this.$router.push({path:'/new/Colleges'})
		if(e==2) this.$router.push({path:'/new/searchFesic'})
		if(e==3) this.$router.push({path:'/new/searchPro'})
		if(e==4) this.$router.push({path:'/new/Colleges'})
	},
	//友情链接
	friendLian(e){
		window.location.href='https://' + e.fc_url
	}
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.b_content {
  .a_content {
	width:100%;
    display: flex;
    background-color: #FFFFFF;
    position: relative;
    .a_left {
      .a_img {
        width: 841px;
        height: 471px;
        background-color: #ffffff;
      }
    }
    .a_right {
      height: 471px;
      background-color: #ffffff;
      z-index: 2;
      .a_title1 {
        width: 368px;
        height: 75px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        font-weight: 400;
        color: #666666;
		background-color: #F5F5F5;
      }
      .a_title2 {
        display: flex;
        padding: 10px 25px;
        box-sizing: border-box;
        align-items: center;
        .a_title3 {
          font-size: 20px;
          font-weight: 400;
          color: #333333;
          margin-right: 23px;
        }
		.g_content{
			display: flex;
			//	align-items: center;
			flex-wrap: wrap;
			width:210px;
			justify-content: space-between;
			.g_title1:hover{
				cursor: pointer;
			}
			.g_title1{
			width: 58px;
			height: 30px;
			background: #337CFF;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 5px;
			font-size: 16px;
			font-weight: 400;
			color: #FFFFFF;
			margin: 10px 0px;
			}
			.g_title2:hover{
				cursor: pointer;
			}
			.g_title2{
			width: 58px;
			height: 30px;
			background:#F5F5F5;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 5px;
			font-size: 16px;
			font-weight: 400;
			color: #337CFF;
			margin: 10px 0px;
			}
		}
		.a_title4:hover{
			color: #F58235;
		}
		.a_title8:hover{
			color: #F58235;
		}
        .a_title4 {
          margin-right: 45px;
          font-size: 18px;
          font-weight: 400;
          color: #337cff;
        }
        .a_title8 {
          margin-right: 45px;
          font-size: 18px;
          font-weight: 400;
          color: #666666;
        }
      }
      .a_title5 {
        display: flex;
        text-align: center;
        padding: 0px 25px;
        box-sizing: border-box;
        margin-bottom:30px;
        align-items: center;
		box-sizing: border-box;
        .a_title6 {
          font-size: 20px;
          font-weight: 400;
          color: #333333;
          margin-right: 23px;
			margin-top:1px;
        }
        .a_input {
			width: 222px;
			height: 40px;
			background: #ffffff;
			border: 1px solid #cccccc;
			border-radius: 2px;
			padding-left: 20px;
			box-sizing: border-box;
			outline: none;
        }
		.a_input:hover{
			border: 1px solid #F58235;
		}
      }
		.a_title7:hover{
			color: #F58235;
			cursor: pointer;
		}
      .a_title7 {
        width: 280px;
        height: 61px;
        background: #337cff;
        border-radius: 5px;
        font-size: 18px;
        font-weight: 400;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: auto;
      }
    }
  }
  .c_content {
	width: 1200px;
	margin: auto;
    background-color: #ffffff;
    padding-top: 70px;
    .c_title1 {
      font-size: 20px;
      font-weight: 600;
      color: #337cff;
      margin: 0px 0px 23px 36px;
      text-align: left;
    }
    .d_title7 {
      font-size:20px;
      font-weight: 600;
      color: #333333;
      margin: 0px 0px 23px 36px;
      text-align: left;
    }
    .d_title9 {
      display: flex;
      flex-wrap: wrap;
      padding-left:50px;
		box-sizing: border-box;
	.d_title10:hover{
		color: #F58235;
		cursor: pointer;
	}
      .d_title10 {
        font-size:16px;
        font-weight: 400;
        color: #333333;
        margin: 0px 20px 35px 0px;
      }
    }
    .c_title2 {
      display: flex;
      justify-content: space-between;
      .c_all:hover {
        transform: scale(1.1);
      }
      .c_all {
        position: relative;
        .vip {
          color: #ffffff;
          font-weight: 600;
          display: flex;
          justify-content: center;
          align-items: center;
          right: 0px;
          position: absolute;
          width: 68px;
          height: 27px;
          background: #ff0006;
          border-radius: 12px 12px 12px 0px;
        }
        .c_title3 {
          font-size:24px;
          font-weight: 400;
          color: #ffffff;
          position: absolute;
          bottom:60px;
          left:87px;
        }
        .c_img {
          width: 285px;
          height: 232px;
          background: #666666;
        }
      }
    }
    .c_title4 {
      display: flex;
      width: 1200px;
      justify-content: space-between;
      margin-bottom: 23px;
      .c_title5 {
        font-size:18px;
        font-weight: 600;
        color: #337cff;
        margin-left: 36px;
      }
		.c_title6:hover{
			color: #F58235;
			cursor: pointer;
		}
      .c_title6 {
        font-size:18px;
        font-weight: 600;
        color: #337cff;
        margin-right: 36px;
      }
    }
    .c_all {
      display: flex;
      justify-content: space-between;
      .c_title7 {
        padding: 36px 0px;
        box-sizing: border-box;
        text-align: center;
        width: 287px;
        height: 435px;
        background: #ffffff;
        box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.16);
        border-radius: 12px;
        .c_title8 {
          .c_logo {
            width: 153px;
            height: 153px;
            border-radius: 50%;
          }
        }
        .c_title9 {
          font-size:20px;
          font-weight: 400;
          color: #333333;
          margin: 35px 0px 23px 0px;
        }
        .c_title10 {
          font-size: 18px;
          font-weight: 400;
          color: #337cff;
          margin-bottom: 23px;
        }
        .c_title11 {
          display: flex;
          justify-content: center;
          .c_title12 {
            width: 55px;
            height: 37px;
            background: #fff1f0;
            border-radius: 5px;
            font-size: 16px;
            font-weight: 400;
            color: #ff4d4f;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .c_title13 {
            width: 48px;
            height: 37px;
            background: #ddf7e2;
            border-radius: 5px;
            font-size: 16px;
            font-weight: 400;
            line-height: 26px;
            color: #3db159;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0px 12px;
          }
          .c_title14 {
            width: 77px;
            height: 37px;
            background: #f0f5ff;
            border-radius: 5px;
            font-size: 16px;
            font-weight: 400;
            color: #3b68df;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
    .cc_all {
      width: 381px;
      text-align: center;
      padding-bottom: 35px;
      box-sizing: border-box;
      background: #ffffff;
      box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.16);
      border-radius: 0px 0px 12px 12px;
		.c_images{
			width: 381px;
			height: 185px;
			border: 1px solid #F5F5F5;
			.cc_img {
			width: 381px;
			height: 185px;
				object-fit:cover;
			}
		}
      .cc_title1 {
        margin: 35px 0px 0px 0px;
        font-size: 20px;
        font-weight: 400;
        color: #337cff;
      }

      .cc_title2 {
        font-size:18px;
        font-weight: 400;
        color: #333333;
        margin: 23px 0px 12px 0px;
      }
      .cc_title3 {
        font-size: 18px;
        font-weight: 400;
        color: #666666;
        width: 300px;
        margin: auto;
        text-align: left;
      }
		.cc_title6:hover{
			color: #F58235;
		}
      .cc_title6 {
        margin: 30px auto;
        width: 145px;
        height: 40px;
        background: #337cff;
        border-radius: 20px;
        font-size: 18px;
        font-weight: 400;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .d_content {
      display: flex;
      justify-content: space-between;
		width: 1200px;
      .d_all {
        display: flex;
        margin-bottom: 35px;
        padding: 0px 20px;
        box-sizing: border-box;
        .d_img {
          width: 199px;
          height: 191px;
          background: rgba(0, 0, 0, 0);
          border-radius: 7px;
          margin-right: 23px;
		object-fit: cover;
        }
        .d_title1 {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
		.d_title2:hover{
			color: #F58235;
			cursor: pointer;
			}
          .d_title2 {
            font-size:16px;
            font-weight: 600;
            color: #333333;
            width: 336px;
            text-align: left;
          }
          .d_title3 {
            display: flex;
            align-items: center;
			.d_title4:hover{
			color: #F58235;
			}
            .d_title4 {
              width: 133px;
              height: 40px;
              background: #f1f6ff;
              border-radius: 2px;
              font-size:16px;
              font-weight: 400;
              color: #3b68df;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-right: 75px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              max-width: 133px;
            }
            .d_title5 {
              display: flex;
              align-items: center;
              .d_title6 {
                font-size:16px;
                font-weight: 400;
                color: #999999;
                margin-left: 8px;
              }
              .iconfont {
                color: #999999;
              }
            }
          }
        }
      }
    }
  }
  .d_content {
    display: flex;
    padding-top: 70px;
    flex-wrap: wrap;
    background-color: #ffffff;
    .dd_all {
      text-align: center;
      margin-bottom: 51px;
      margin-left: 15px;
      .d_all {
        width: 140px;
        height: 140px;
        background: #337cff;
        border-radius: 50%;
        .d_title1 {
          margin-left: 85px;
          color: #ffffff;
          font-weight: 600;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 89px;
          height: 35px;
          background: #ff0006;
          border-radius: 12px 12px 12px 0px;
          margin-bottom: 4px;
        }
        .iconfont {
          color: #ffffff;
          font-size: 70px;
        }
      }
      .d_title2 {
        font-size: 20px;
        font-weight: 400;
        color: #000000;
        margin-top: 23px;
      }
		.d_title2:hover {
		color: #ff0006;
		}
    }
  }
}
.f_content {
	width: 1200px;
  display: flex;
  margin: auto;
  .f_img {
    width: 730px;
    height: 549px;
  }
  .f_all {
    width: 470px;
    background-color: #ffffff;
    padding: 0px 47px;
    box-sizing: border-box;
    .f_title1 {
      font-size: 18px;
      font-weight: 400;
      color: #666666;
      margin: 21px 0px 56px 0px;
    }
    .f_title2 {
      display: flex;
      align-items: center;
      width: 390px;
      height: 50px;
      background: #f5f5f5;
      border-radius: 5px;
      padding-left: 23px;
      box-sizing: border-box;
      .iconfont {
        color: #333333;
      }
      .f_title4 {
        margin-left: 10px;
        height: 40px;
        width: 400px;
        background: #f5f5f5;
      }
    }
    .f_title5 {
      width: 390px;
      height: 61px;
      background: #337cff;
      opacity: 1;
      border-radius: 5px;
      margin: 47px 0px 16px 0px;
      font-size: 18px;
      font-weight: 400;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .f_title6 {
      font-size: 14px;
      font-weight: 400;
      color: #999999;
      text-align: left;
      margin-left: 20px;
    }
    .f_title10 {
      display: flex;
      justify-content: space-between;
      .f_title11 {
        font-size: 14px;
        font-weight: 400;
        color: #999999;
        text-align: left;
        margin-left: 20px;
      }
      .f_title12 {
        font-size: 14px;
        font-weight: 400;
        color: #337cff;
        margin-right: 20px;
      }
    }
    .f_title3 {
      display: flex;
      align-items: center;
      margin: 28px 0px;
      .f_title7 {
        width: 238px;
        height: 50px;
        background: #f5f5f5;
        display: flex;
        align-items: center;
        .iconfont {
          color: #333333;
        }
        .f_title8 {
          margin-left: 10px;
          height: 40px;
          width: 145px;
          background: #f5f5f5;
        }
      }
      .f_title9 {
        width: 132px;
        height: 49px;
        background: #337cff;
        border-radius: 5px;
        font-size: 16px;
        font-weight: 400;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 23px;
      }
    }
  }
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  margin: 0;
}
/deep/ .el-carousel__container {
  height: 471px;
  width:833px;
}
/deep/ .el-carousel__indicators--horizontal {
  left: 35%;
}
/deep/.el-carousel__indicators--horizontal {
  padding: 100px;
}
.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}
.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
</style>
