<template>
<div>
	<myNav/>
		<div class="r_content">
			<div class="a_title1">高考要闻</div>
			<div v-for="(item,index) in list" :key="index">
				<div class="a_all" @click="detail(item)">
					<div style="width:240px;height: 132px;margin-right: 20px;border: 1px solid #F5F5F5;"><img class="a_img" :src="item.img" alt=""></div>
					<div style="display: flex;flex-direction: column;justify-content:space-around;height:164px;width:960px;">
						<div class="a_title2">{{item.nstitle}}</div>
						<div class="a_title3">{{item.title}}</div>
						<div class="a_title4">
							<i class="iconfont icon-shizhong"></i>
							<span class="a_title5">{{item.create_time}}</span>
						</div>
					</div>
				</div>
			</div>
			<div class="search_all" style="margin-top:50px">
			<el-pagination
			background
			layout="prev,pager,next"
			:total="list.length"
			:page-size="4"
			:pager-count="5"
			:current-page="page"
			@current-change="currentChange"
			>
			</el-pagination>
			</div>
		</div>
	<Footer/>
</div>
</template>
<script>
	import Footer from '@/components/footer'
export default {
components: {
	Footer
},
data(){
	return {
		id:"",
		list:[],
		page:1
	};
	},
	activated() {
		this.dataAll()
	},
	methods:{
		//获取新闻列表
		dataAll(){
			this.$axios({
				url:'News/NewsHomelist',
				method:'post',
				data:{
					page:1
				}
			}).then(res=>{
				this.list=res.data.data.data
			})
		},
		//分页
		currentChange(e){
			this.$axios({
				url:'News/NewsHomelist',
				method:'post',
				data:{
					page:e
				}
			}).then(res=>{
				this.list=res.data.data.data
			})
		},
		//新闻详情
		detail(e){
			this.$router.push({path:'/news',query:{id:e.id}})
		}
	},
}

</script>

<style lang="less" scoped>
	.r_content{
		width: 1200px;
		background-color: #FFFFFF;
		margin: auto;
		padding: 20px 30px;
		box-sizing: border-box;
		.a_title1{
			font-size:18px;
			font-weight: 600;
			color: #337CFF;
		}
		.search_all{
			display: flex;
			align-items: center;
			justify-content: center;
		}
		.a_all{
			width: 1200px;
			display: flex;
			align-items: center;
			margin-top: 30px;
			border-bottom: 1px solid #CCCCCC;
			padding-bottom: 30px;
			.a_img{
				width: 240px;
				height: 132px;
				background: rgba(0, 0, 0, 0);
				border-radius: 6px;
				margin-right: 20px;
			}
			.a_title2{
				font-size:16px;
				font-weight: 600;
				color: #333333;
			}
			.a_title3{
				font-size: 18px;
				font-weight: 400;
				color: #707070;
				display: -webkit-box; 
				-webkit-line-clamp:3; 
				-webkit-box-orient: vertical; 
				overflow: hidden;
			}
			.a_title4{
				display: flex;
				align-items:center;
				.iconfont{
					color: #999999;
				}
				.a_title5{
					font-size: 16px;
					font-weight: 400;
					color: #999999;
					margin-left: 10px;
				}
			}
		}
	}
</style>
