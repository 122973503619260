<template>
	<div>
		<myNav/>
		<login-pop/> <!-- 未登录弹出 -->
		<div class="content">
			<div class="h_content">
                <div class="a_title1">购买会员卡</div>
                <div class="a_all">
                    <div class="a_title2">商品名称：志愿卡</div>
                   <!-- <div class="a_title3">价格：¥{{list.money}}</div> -->
                    <div style="margin-top: 30px;">
                        <span class="a_title4">价格：</span>
                        <span class="a_title5">¥{{list.money}}</span>
                    </div>
                </div>
                <div style="margin:30px 0px">
                    <span class="a_title6">选择支付方式</span>
                    <span class="a_title7">请您确认订单信息无误后选择支付方式购买</span>
                </div>
                <div class="b_all">
                    <div class="b_title1">
                        <div class="b_title3">
                            <div class="b_title4"></div>
                        </div>
                        <div><i class="iconfont icon-weixinzhifu"></i></div>
                        <div class="b_title2">微信</div>
                    </div>
                    <!-- <div class="b_title1">
                        <div class="b_title3">
                            <div class="b_title4"></div>
                        </div>
                        <div><i class="iconfont icon-zhifubao"></i></div>
                        <div class="b_title2">支付宝</div>
                    </div> -->
                </div>
				<vue-qr :text="parUrl" :callback="test" qid="testid"></vue-qr>
                <div class="btn">立即购买</div>
			</div>
		</div>
		<Footer/>
	</div>
</template>

<script>
	import Footer from '@/components/footer'
	import VueQr from 'vue-qr'
	export default {
		components: {
			Footer,
			VueQr
		},
		props: ['myValue'],
		data() {
			return {
				list:{},
				parUrl:''
			}
		},
		activated(){
			this.list=this.$store.state.vipData
			this.pay()
		},
		methods: {
			test(dataUrl,id){
				console.log(dataUrl, id)
			},
			// 微信支付
			pay(){
				let __self = this;
				__self.$axios({
					url: 'member.Personal/CreateOrder',
					data: {
						id:__self.list.id,
						member_id:__self.$store.state.userData.user_id,
						pay_type:1,
						source:4,
						addr:"成都市双流区",
						citycode: 510116
					}
				}).then(res => {
					if(res.status ==200){
						this.parUrl=res.data.data
						console.log(res)
					} else {
						__self.$message({
							message:res.msg,
							type:'warning'
						})
					}
				})
			},
		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
	.content {
		.h_content{
			width:1200px;
			height: 1029px;
			background: #FFFFFF;
			padding:20px;
			box-sizing: border-box;
            .a_title1{
            font-size: 20px;
            font-weight: 400;
            color: #666666;
            margin-bottom: 16px;
            }
            .a_all{
                width:100%;
                height: 130px;
                background: #F2F8FF;
                padding:20px;
                box-sizing: border-box;
                .a_title2{
                font-size: 18px;
                font-weight: 400;
                color: #666666;
                }
                .a_title3{
                font-size: 16px;
                font-weight: 400;
                color: #999999;
                margin: 10px 0px;
                }
                .a_title4{
                   font-size: 16px;
                font-weight: 400;
                color: #999999; 
                }
                .a_title5{
                   font-size: 16px;
                font-weight: 400;
                color: #F94D28; 
                }
            }
            .a_title6{
                font-size: 20px;
                font-weight: 400;
                color: #666666;
                margin-right: 50px;
            }
            .a_title7{
                font-size: 18px;
                font-weight: 400;
                color: #337CFF;
            }
            .b_all{
                display: flex;
                align-items: center;
                .b_title1{
                    width: 200px;
                    height: 50px;
                    border: 1px solid #CCCCCC;
                    opacity: 1;
                    border-radius: 4px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-right: 40px;
                    .b_title3{
                        width: 10px;
                    height: 10px;
                    border: 1px solid #21A9F1;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-right: 10px;
                    .b_title4{
                        width: 4px;
                        height: 4px;
                        background: #21A9F1;
                        border-radius: 50%;
                    }
                    }
                  .icon-zhifubao{
                      color:#02A9F1;
                      font-size:20px;
                  }  
                  .icon-weixinzhifu{
                       color:#09BB07;
                      font-size:20px;
                  }
                  .b_title2{
                margin-left: 10px;
                font-size: 16px;
                font-weight: 400;
                color: #333333;
                  }
                }
            }
            .btn{
                width: 140px;
                height: 50px;
                background: #337CFF;
                border-radius: 4px;
                margin: 40px auto;
                font-size: 16px;
                font-weight: 400;
                color: #FFFFFF;
                display: flex;
                align-items: center;
                justify-content: center;
            }
		}
	}
</style>
