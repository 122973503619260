<template>
	<div>
		<!-- <myNav :loginValue="token"/> -->
		<myNav/>
		<login-pop/>
		<div class="content">
		<div class="g_content">
				<div class="g_all">
					<div style="text-align: left;"><img class="g_img" :src="detail.logo" alt=""></div>
					<div class="g_title1">
						<div class="g_title2">
							<div class="g_title3">{{detail.title}}</div>
							<div class="g_title4" @click="alternative()" v-if="spare_type==2">备选</div>
						</div>
						<div class="g_title5">
							<div class="g_title6">博士点：{{detail.doc_point ? detail.doc_point : '-'}}</div>
							<div class="g_title6">硕士点：{{detail.master_program ? detail.master_program : '-'}}</div>
						</div>
					</div>
				</div>
				<div class="g_title7"></div>
				<div class="gg_content">
					<div class="gg_title1">基本信息</div>
					<div class="gg_all">
						<div class="gg_title2">
							<div class="gg_title3">国标代码：{{detail.code}}</div>
							<div class="gg_title3">所在省市：{{detail.province_name}}</div>
							<div class="gg_title3">隶属部门：{{detail.subordinate}}</div>
							<div class="gg_title3">办学性质：{{detail.is_pubilc == 1 ? '公办' : '民办'}}</div>
							<div class="gg_title3">院校层次：{{detail.school_type}}</div>
						</div>
						<div class="gg_title2">
							<div class="gg_title3">院校类型：{{detail.type}}</div>
							<div class="gg_title3">招生电话：{{detail.telephone}}</div>
							<div class="gg_title3">院校地址：{{detail.address}}</div>
							<div class="gg_title3">招生网址：{{detail.web_url}}</div>
						</div>
					</div>
				</div>
				<div class="g_content1">
					<div @mouseover="monseEnter(index)" @mouseleave="monseOut(index)" @click="xuanZe(index)" :class="index1==index ? 'g_next1' : 'g_next2'" v-for="(item,index) in list2" :key="index">{{item.name}}</div>
				</div>
				<div class="g_title8"></div>
				<div v-if="index1==0">
					<div class="f_content1" v-show="myShow" @mouseover="monseEnter(0)" @mouseleave="monseOut(0)">
						<div @click="nextData(index)" class="f_title" v-for="(item,index) in list3" :key="index">{{item.name}}</div>
					</div>
					<div class="g_title9" v-if="myIndex==0">
						<div class="g_title10">院校简介</div>
						<div style="padding: 0px 30px;box-sizing: border-box;"><span v-html="detail.brief" class="g_title11"></span></div>
					</div>
					<div class="g_title9" v-if="myIndex==1">
						<div class="g_title10">开设专业</div>
						<div v-for="(item,index) in schoolMajor" :key="index" style="margin-bottom: 20px;">
							<div class="g_title12">{{item.bname}}</div>
							<div style="display: flex;flex-wrap: wrap;margin-left: 32px;">
								<div class="g_title13" v-for="(item_1,index_1) in item.childer" :key="index_1">{{item_1.name}}</div>
							</div>
						</div>
					</div>
					<div class="g_title9" v-if="myIndex==2">
						<div class="g_title10">校园风光</div>
						<div class="img_all">
							<img v-for="(item,index) in detail.campus_scenery" :key='index' class="g_imge" :src="item" alt="">
						</div>
					</div>
				</div>
				<div v-if="index1==3">
					<div class="g_title9">
						<div class="g_title10">简章查询</div>
						<div class="g_title15" @click="rulesDetail(item.id)" v-for="(item,index) in briefs.list" :key="index">
							<div class="yuan"></div>
							<div class="g_title14">{{item.title}}</div>
						</div>
					</div>
				</div>
				<div v-if="index1==1">
					<!-- 院校录取概率 -->
					<div class="g_title9">
						<div class="g_title10">录取信息</div>
						<div class="g_title10" style="font-size: 18px;">以下数据来源于考试院网站公布数据及全网公开数据，经供参考请以高招大本为准，并关注各省招生考试院网站最新公告。</div>
						<div style="display: flex;justify-content: space-around;">
							<el-form ref="form">
								<el-form-item>
								<el-select style="width: 500px;" v-model="scores.year" @change="yearChange">
									<el-option :label="index" :value="index" v-for="(item,index) in scores.list" :key="index"></el-option>
								</el-select>
								</el-form-item>
							</el-form>
							<el-form ref="form1">
								<el-form-item>
								<el-select style="width: 500px;" v-model="scores.batch_id">
									<el-option :label="item.name" :value="item.id" v-for="(item,index) in scores.list[scores.year]" :key="index"></el-option>
								</el-select>
								</el-form-item>
							</el-form>
						</div>
						<div style="display: flex;justify-content:center;margin-top: 20px;">
							<div class="g_title16">年份</div>
							<div class="g_title16" style="border-left: 0px;">录取标准</div>
							<div class="g_title16" style="border-left: 0px;">分数/位次</div>
						</div>
						<div v-for="(item,index) in admissionList" :key="index" style="display: flex;justify-content:center;">
							<div class="g_title16" style="background-color: #FFFFFF;">{{item.year}}</div>
							<div class="g_title16" style="border-left: 0px;background-color: #FFFFFF;">{{item.batch}}</div>
							<div class="g_title16" style="border-left: 0px;background-color: #FFFFFF;">{{item.lowest_score}}/{{item.lowest_order}}</div>
						</div>
					</div>
					<!-- 专业录取概率 -->
					<div class="g_title9">
						<div class="g_title10">专业录取数据</div>
						<div style="display: flex;justify-content: space-around;">
							<el-form ref="form">
								<el-form-item>
								<el-select style="width: 500px;" v-model="z_scores.year" @change="yearChange1">
									<el-option :label="index" :value="index" v-for="(item,index) in z_scores.list" :key="index"></el-option>
								</el-select>
								</el-form-item>
							</el-form>
							<el-form ref="form1">
								<el-form-item>
								<el-select style="width: 500px;" v-model="z_scores.batch_id" @change="yearChange2">
									<el-option :label="item.name" :value="item.id" v-for="(item,index) in z_scores.list[z_scores.year]" :key="index"></el-option>
								</el-select>
								</el-form-item>
							</el-form>
						</div>
						<div style="display: flex;justify-content:center;margin-top: 20px;">
							<div class="g_title17" style="width: 340px;height:60px;">专业</div>
							<div class="g_title17" style="border-left: 0px;height:60px;">批次</div>
							<div class="g_title17" style="border-left: 0px;height:60px;">计划数</div>
							<div class="g_title17" style="border-left: 0px;height:60px;">最低分</div>
							<div class="g_title17" style="border-left: 0px;height:60px;">最低位次</div>
						</div>
						<div v-for="(item,index) in z_admissionList.data" :key="index" style="display: flex;justify-content:center;">
							<div class="g_title17" style="background-color: #FFFFFF;width: 340px;">{{item.title}}</div>
							<div class="g_title17" style="border-left: 0px;background-color: #FFFFFF;">{{item.batch}}</div>
							<div class="g_title17" style="border-left: 0px;background-color: #FFFFFF;">{{item.pro_enr}}</div>
							<div class="g_title17" style="border-left: 0px;background-color: #FFFFFF;">{{item.lowest_score}}</div>
							<div class="g_title17" style="border-left: 0px;background-color: #FFFFFF;">{{item.lowest_order}}</div>
						</div>
						<div class="search_all">
							<el-pagination
							background
							hide-on-single-page
							layout="prev,pager,next"
							:total="z_admissionList.total"
							:page-size="10"
							:pager-count="7"
							:current-page="z_admissionList.current_page"
							@current-change="currentChange"
							>
							</el-pagination>
						</div>
					</div>
				</div>
			<div v-if="index1==2">
				<div class="g_title9">
					<div class="g_title10">招生计划</div>
					<div class="g_title10" style="font-size: 18px;">以下数据来源于考试院网站公布数据及全网公开数据，经供参考请以高招大本为准，并关注各省招生考试院网站最新公告。</div>
					<div style="display: flex;justify-content: space-around;">
						<el-form ref="form">
							<el-form-item>
								<el-select style="width: 500px;" v-model="enr.year" @change="enr_yearChange">
									<el-option :label="index" :value="index" v-for="(item,index) in search_enr.batch" :key="index"></el-option>
								</el-select>
							</el-form-item>
						</el-form>
						<el-form ref="form1">
							<el-form-item>
								<el-select style="width: 500px;" v-model="enr.batch_id">
									<el-option :label="item.name" :value="item.id" v-for="(item,index) in search_enr.batch[enr.year]" :key="index"></el-option>
								</el-select>
							</el-form-item>
						</el-form>
					</div>
					<div style="display: flex;justify-content:center;margin-top: 20px;">
						<div class="g_title16">专业名称/代码</div>
						<div class="g_title16" style="border-left: 0px;">计划人数</div>
					</div>
					<div v-for="(item,index) in enr.list" :key="index" style="display: flex;justify-content:center;">
						<div class="g_title16" style="background-color: #FFFFFF;">{{item.title}}</div>
						<div class="g_title16" style="border-left: 0px;background-color: #FFFFFF;">{{item.pro_enr}}</div>
					</div>
				</div>
			</div>
				<div v-if="index1==4">
					<div class="g_title9">
						<div class="g_title10">就业前景</div>
						<div class="h_content" v-for="(item,index) in detail.attr" :key="index">
							<div class="h_title1">{{index}}</div>
							<div class="h_all">
								<div class="h_title2">
									<div class="h_title3" :style="{width: item + '%'}"></div>
								</div>
								<div class="h_title4">{{item}}%</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<Footer/>
	</div>
</template>

<script>
	import Footer from '@/components/footer'
	export default {
		components: {
			Footer
		},
		data() {
			return {
				myShow:false,
				school_id: 0,
				form:{name:''},
				form1:{name:''},
				myIndex:0,
				index1:0,
				list2:[{name:'学校概括'},{name:'录取信息'},{name:'招生计划'},{name:'简章查询'},{name:'高校就业'}],
				list3:[{name:'院校首页'},{name:'开设专业'},{name:'院校风光'}],
				// 学校列表 - 详情
				detail: {}, // 学校列表
				search_enr: {},
				enr: {list: [],year: 0,batch_id: 0},
				briefs: {list: [],page: 0},
				scores: {list:[],year: 0,batch_id: 0},
				z_scores: {list:[],year: 0,batch_id: 0},
				admissionList: [],
				z_admissionList:[],
				schoolMajor: [],
				spare_type:'',
				loginShow:false
			}
		},
			// watch:{
			// '$route':function(){
			// this.aaa()
			// }
			// },
		activated() {
			this.index1=0
			this.details()
		},
		methods: {
			//鼠标移入显示
			monseEnter(e){
				if(e==0) this.myShow=true
			},
			//鼠标移出影藏
			monseOut(){
				this.myShow=false
			},
			//简章详情
			rulesDetail(e){
				this.$router.push({path:'/mySearch/rules',query:{id:e}})
			},
			//详情数据公共函数
			details(){
				this.school_id =this.$store.state.shcoolDetail.school_id
				this.spare_type=this.$store.state.shcoolDetail.spare_type
				this.$axios({
					url: 'School/detail',
					method: 'POST',
					data: {school_id:this.school_id}
				}).then(res=>{
					this.detail = res.data.data.detail
					this.detail.campus_scenery = this.detail.campus_scenery.split(',')
					this.search_enr = res.data.data.search_enr
					for (let index in this.search_enr.batch) {
						if (this.enr.index > index) {
							break
						}
						this.enr.year = index
						this.enr.batch_id = this.search_enr.batch[index].length ? this.search_enr.batch[index][0].id : 0
					}
				})
				this.$axios({
					url: 'school/pcschoolmajor',
					method:'post',
					data: {school_id: this.school_id,}
				}).then(res => {
					this.schoolMajor = res.data.data
				})
			},
			//加入备选和移除备选
			alternative(){
				if(this.spare_type==2){
					this.$axios({
						url:'Spare/add',
						method:'post',
						data:{
							member_id:this.$store.state.userData.user_id,
							school_id:this.$store.state.shcoolDetail.school_id
						}
					}).then(res=>{
						if(res.data.status==200){
							this.spare_type=1
							this.$store.commit('detail',{school_id:this.school_id,spare_type:1})
							this.$message({
								message:'添加备选成功',
								type:'warning'
							})
						}
					})
				}
				// else{
				// 	this.$axios({
				// 		url:'Spare/add',
				// 		method:'post',
				// 		data:{
				// 			id:this.$store.state.userData.user_id
				// 		}
				// 	}).then(res=>{
				// 		if(res.data.status){
				// 			this.spare_type=2
				// 			this.$store.commit('detail',{school_id:this.school_id,spare_type:this.spare_type})
				// 			this.$message({
				// 				message:'移除备选成功',
				// 				type:'success'
				// 			})
				// 		}
				// 	})
				// }
			},
			//院校专业展示
			nextData(e){
				this.myIndex=e
			},
			proChange () {
				this.getBatch()
			},
			//年份选中状态
			brief () {
				this.$axios({
					url: 'School/news',
					data:{
						school_id:this.school_id,
						page:this.briefs.page,
					},
				}).then((res) => {
					if (res.data.status == 200){
						this.briefs.page++;
						this.briefs.list = this.briefs.list.concat(res.data.data.data);
					}
				})
			},
			xuanZe(i) {
				this.index1 = i
				if (i == 1) this.score(),this.score1()
				if (i == 3) this.brief()
				if (i == 2) this.searchEnr()
			},
			score () {
				this.$axios({
					url: 'School/score_search',
					data:{
						"school_id":this.school_id,
						mid: this.$store.state.userData.user_id
					},
				}).then((res) => {
					this.scores.list = res.data.data.batch
					for (let index in this.scores.list) {
						if (this.scores.index > index) {
							break
						}
						this.scores.year = index
						this.scores.batch_id = this.scores.list[index][0].id
					}
					this.scoreAdmission()
				})
			},
			//专业年份
			score1 () {
				this.$axios({
					url: 'School/score_search',
					data:{
						"school_id":this.school_id,
						mid: this.$store.state.userData.user_id
					},
				}).then((res) => {
					this.z_scores.list = res.data.data.batch
					for (let index in this.z_scores.list) {
						if (this.z_scores.index > index) {
							break
						}
						this.z_scores.year = index
						this.z_scores.batch_id = this.z_scores.list[index][0].id
					}
					this.zhuanyeScore()
				})
			},
			yearChange (e) {
				this.scores.batch_id = this.scores.list[e][0].id
				this.scoreAdmission()
			},
			//专业年份
			yearChange1 (e) {
				console.log(this.z_scores.list)
				this.z_scores.batch_id = this.z_scores.list[e][0].id
				this.zhuanyeScore()
			},
			//普通类
			yearChange2 (e) {
				console.log(e)
				//this.z_scores.batch_id = this.z_scores.list[e][0].id
				this.zhuanyeScore()
			},
			enr_yearChange (e) {
				this.enr.batch_id = this.search_enr.batch[e][0].id
				this.searchEnr()
			},
			//查专业概率
			zhuanyeScore(){
				this.$axios({
					url: 'school/getMajorList',
					data: {
						school_id: this.school_id,
						year: this.z_scores.year,
						batch_id: this.z_scores.batch_id
					}
				}).then(res => {
					if (res.data.status == 200) {
						this.z_admissionList = res.data.data
					} else {
						this.$message(res.data.msg)
					}
				})
			},
			//分页
			currentChange(e){
				this.$axios({
					url: 'school/getMajorList',
					data: {
						school_id: this.school_id,
						year: this.z_scores.year,
						batch_id: this.z_scores.batch_id,
						page:e
					}
				}).then(res => {
					if (res.data.status == 200) {
						this.z_admissionList = res.data.data
					} else {
						this.$message(res.data.msg)
					}
				})
			},
			//专业分数
			scoreAdmission () {
				this.$axios({
					url: 'School/score_admission',
					data: {
						school_id: this.school_id,
						year: this.scores.year,
						batch_id: this.scores.batch_id
					}
				}).then(res => {
					if (res.data.status == 200) {
						this.admissionList = res.data.data.data
					} else {
						this.$message(res.data.msg)
						// this.$router.push({path:'/Colleges'})
					}
				})
			},

			searchEnr () {
				this.$axios({
					url: 'School/plan',
					data: {
						school_id: this.school_id,
						year: this.enr.year,
						batch_id: this.enr.batch_id
					}
				}).then(res => {
					if (res.data.status == 200) {
						this.enr.list = res.data.data.data
					} else {
						this.$message(res.data.msg)
						this.$router.push({path:'/new/Colleges'})
					}
				})
			}
		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
	.content {
		.g_content{
			background-color: #FFFFFF;
			width: 1200px;
			margin: auto;
			padding: 30px 0px;
			box-sizing: border-box;
			.g_all{
				display: flex;
				align-items: center;
				.g_img{
					width: 100px;
					height: 100px;
					margin:0px 20px 0px 30px;
					border-radius: 50%;
				}
				.g_title1{
					.g_title2{
						display: flex;
						align-items: center;
						.g_title3{
							font-size:24px;
							font-weight: 400;
							color: #333333;
							margin-right: 20px;
						}
						.g_title4{
							width:60px;
							height: 21px;
							background: #3B68DF;
							border-radius: 10px;
							font-size: 12px;
							font-weight: 400;
							color: #FFFFFF;
							display: flex;
							align-items: center;
							justify-content: center;
						}
					}
					.g_title5{
						display: flex;
						align-items: center;
						margin-top: 26px;
						.g_title6{
							font-size: 16px;
							font-weight: 400;
							color: #999999;
							margin-right: 30px;
						}
					}
				}
			}
			.g_title7{
				width: 1140px;
				height: 0px;
				border: 2px solid #CCCCCC;
				margin:22px auto;
			}
			.gg_content{
				padding: 0px 30px;
				box-sizing: border-box;
				background-color: #FFFFFF;
				.gg_title1{
					font-size: 20px;
					font-weight: 400;
					color: #3B68DF;
					margin-bottom: 10px;
					text-align: left;
				}
				.gg_all{
					display: flex;
					.gg_title2{
						margin-right: 108px;
						text-align: left;
						.gg_title3{
							font-size: 16px;
							font-weight: 400;
							color: #666666;
							margin-bottom: 10px;
						}
					}
				}
			}
			
			.g_content1{
				display: flex;
				justify-content:space-between;
				.g_next1:hover{
					cursor: pointer;
				}
				.g_next2:hover{
					cursor: pointer;
				}
				.g_next1{
					width: 240px;
					height: 55px;
					background: #F2F8FF;
					font-size: 20px;
					font-weight: 400;
					color: #3B68DF;
					display: flex;
					align-items: center;
					justify-content: center;
					border-bottom: 2px solid #3B68DF;
				}
				.g_next2{
					width: 240px;
					height: 55px;
					font-size: 20px;
					font-weight: 400;
					color:#333333;
					display: flex;
					align-items: center;
					justify-content: center;
					border-bottom: 2px solid #3B68DF;
				}
			}
			.f_content1{
				text-align: center;
				width:240px;
				// .f_all{
				// 	font-size: 20px;
				// 	font-weight: 400;
				// 	color: #d34b16;
				// 	margin-right: 20px;
				// 	border-bottom: 2px solid #d34b16;
				// 	padding-bottom: 10px;
				// }
				.f_all:hover{
					color: #3B68DF;
					background-color: #F2F8FF;
				}
				.f_title:hover{
					color: #3B68DF;
					background-color: #F2F8FF;
				}
				.f_title{
					font-size: 20px;
					font-weight: 400;
					height: 40px;
					padding-bottom: 10px;
					display: flex;
					align-items: center;
					justify-content: center;
				}
			}
			// .g_title8{
			// 	width: 1200px;
			// 	height: 2px;
			// 	background: #d34b16;
			// }
			.g_title9{
				.search_all{
					display: flex;
					align-items: center;
					justify-content: center;
					height: 200px;
				}
				.h_content{
					margin-left: 32px;
					.h_title1{
						font-size: 20px;
						font-weight: 400;
						color: #333333;
					}
					.h_all{
						display: flex;
						align-items: center;
						margin: 7px 0px;
						.h_title2{
						width: 570px;
						height: 16px;
						background: #F5F5F5;
						border-radius: 99px;
							.h_title3{
							width: 292px;
							height: 16px;
							background: #3B68DF;
							border-radius: 99px;
							}
						}
						.h_title4{
							font-size: 16px;
							font-weight: 400;
							color: #333333;
							margin-left: 50px;
						}
					}
				}
				.img_all{
					display: flex;
					flex-wrap: wrap;
					.g_imge{
						width: 270px;
						height: 203px;
						background: #F5F5F5;
						border-radius: 10px;
						margin: 23px 15px;
					}
				}
				.g_title10{
					font-size: 24px;
					font-weight: 400;
					color: #333333;
					margin: 30px 0px 20px 30px;
					text-align: left;
				}
				.g_title11{
					font-size: 20px;
					font-weight: 400;
					color: #333333;
					margin:0px 30px 0px 30px;
				}
				.g_title12{
					font-size: 20px;
					font-weight: 400;
					color: #3B68DF;
					margin-left: 32px;
				}
				.g_title13{
					padding: 5px 15px;
					background: #F5F5F5;
					border-radius: 10px;
					font-size: 16px;
					font-weight: 400;
					color: #666666;
					display: flex;
					align-items: center;
					justify-content: center;
					margin-top: 10px;
					margin-right: 20px;
					box-sizing: border-box;
				}
				.yuan{
					width: 12px;
					height: 12px;
					background: #3B68DF;
					border-radius: 50%;
				}
				.g_title15{
					display: flex;
					align-items: center;
					margin-left: 32px;
					margin-bottom: 12px;
					.g_title14{
						margin-left:10px;
						font-size: 20px;
						font-weight: 400;
						color: #333333;
					}
					.g_title14:hover{
						cursor: pointer;
					}
				}
				.g_title16{
					width: 571px;
					height: 60px;
					display: flex;
					align-items: center;
					justify-content: center;
					background: #F2F8FF;
					border: 1px solid #CCCCCC;
					font-size: 20px;
					font-weight: 400;
					color: #333333;
				}
				.g_title17{
					width:200px;
					height:200px;
					display: flex;
					align-items: center;
					justify-content: center;
					background: #F2F8FF;
					border: 1px solid #CCCCCC;
					font-size: 20px;
					font-weight: 400;
					color: #333333;
				}
			}
		}
	}
</style>
