<template>
	<div>
		<myNav/>
		<div class="content">
			<div class="a_content" style="margin-top: 40px;">
				<div class="a_all">
					<div v-for="(item,index) in list2" :key="index" @click="isOk(index)" :class="isCheck1==index ? 'a_title1' : 'a_title8'">{{item.name}}</div>
				</div>
				<div class="a_all1">
					<div @click="area(index)" :class="isCheck==index ? 'a_title2' : 'a_title7'" v-for="(item,index) in list1" :key="index">{{item.name}}</div>
				</div>
				<div class="a_all2" v-for="(item,index) in 6" :key="index">
					<div><img class="a_img" src="@/assets/tu2.png" alt=""></div>
					<div class="a_title3">
						<div class="a_title4">想申请美国研究生奖学金，一定要学会这四步…</div>
						<div class="a_title5">美国研究生一般都会设置奖学金，不但给同学们在经济上减轻压力，更能在学习履历上添加光彩。但是，美国留学生申请的竞争日益激烈…</div>
						<div class="a_title6">时间：2021-3-8</div>
					</div>
				</div>
			</div>
		</div>
		<Footer/>
	</div>
</template>

<script>
	import Footer from '@/components/footer.vue'
	export default {
		components: {
			Footer
		},
		data() {
			return {
				isCheck: 0,
				isCheck1: 0,
				list1: [{
					name: '美国'
				}, {
					name: '加拿大'
				}, {
					name: '英国'
				}, {
					name: '澳大利亚'
				}, {
					name: '新西兰'
				}, {
					name: '日本'
				}],
				list2: [{
					name: '留学费用'
				}, {
					name: '留学新闻'
				}, {
					name: '名校介绍'
				}, {
					name: '申请条件'
				}]
			}
		},
		methods: {
			isOk(e) {
				this.isCheck1 = e
			},
			area(e) {
				this.isCheck = e
			}
		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
	.content {
		display: flex;
		justify-content: center;

		.a_content {
			width: 1200px;

			.a_all {
				display: flex;
				width: 1200px;
				height: 80px;
				background: #F2F8FF;
				margin: auto;

				.a_title1 {
					width: 300px;
					height: 80px;
					background: #337CFF;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 24px;
					font-weight: 400;
					color: #FFFFFF;
				}

				.a_title8 {
					width: 300px;
					height: 80px;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 24px;
					font-weight: 400;
					color: #333333;
				}
			}

			.a_all1 {
				display: flex;
				align-items: center;
				padding: 20px 30px;
				box-sizing: border-box;

				.a_title2 {
					font-size: 20px;
					font-weight: 400;
					color: #3B68DF;
					margin-right: 20px;
				}

				.a_title7 {
					font-size: 20px;
					font-weight: 400;
					color: #333333;
					margin-right: 20px;
				}
			}

			.a_all2 {
				display: flex;
				align-items: center;
				margin-bottom: 30px;

				.a_img {
					width: 300px;
					height: 160px;
					background: #F2F8FF;
					border-radius: 10px;
					margin-right: 31px;
				}

				.a_title3 {
					.a_title4 {
						font-size: 18px;
						font-weight: 400;
						color: #333333;
						text-align: left;
					}

					.a_title5 {
						font-size: 16px;
						font-weight: 400;
						color: #999999;
						margin: 12px 0px;
						text-align: left;
					}

					.a_title6 {
						font-size: 16px;
						font-weight: 400;
						color: #999999;
						text-align: left;
					}
				}
			}
		}
	}
</style>
