<template>
<div>
	<myNav />
	<login-pop/><!-- 跳转登录 -->
	<vipPop/>
	<div class="a_content">
		<div class="a_title1">查大学看要求</div>
		<div class="a_title2">输入感兴趣大学看</div>
		<div class="a_title3">选科要求</div>
		<div class="a_all" style="margin-bottom: 0px;">
			<input @blur="onBlur" @keydown.enter="searchAll()"  value='' v-model="form.school" class="a_input" placeholder="请输入院校名称" type="text">
			<div class="a_title4" @click="searchAll()">
				<i class="iconfont icon-icon-xian-02"></i>
				<span class="a_title8">搜索</span>
			</div>
		</div>
		<div @click="schoolList(item)" class="a_title9" v-show="index<10" v-for="(item,index) in listSchool" :key="index">
			<span>{{item.title}}</span>
			<i class="iconfont icon-icon-xian-11"></i>
		</div>
		<div v-if="listSchool.length==0" class="a_title5">热门搜索</div>
		<div v-if="listSchool.length==0 " class="a_title6">
			<div @click="host(item.search_keyword)" class="a_title7" v-for="(item,index) in list" :key="index">{{item.search_keyword}}</div>
		</div>
	</div>
	<Footer />
</div>
</template>

<script>
	import Footer from '@/components/footer.vue'
	export default {
		components: {
			Footer
		},
		data() {
			return {
				listSchool:[],
				form:{
					school:'',
					mid:''
				},
				list:[]
			}
		},
		activated() {
			this.listSchool=[]
			this.dataAll()
		},
		methods: {
			//热门大学
			host(e){
				this.form.school=e
			},
			//获取所有数据
			dataAll(){
				this.$axios({
					url:'Search/host',
					method:'post',
					data:{
						search_type:1
					}
				}).then(res=>{
					this.list=res.data.data
				})
			},
			//失焦
			onBlur(e){
				this.listSchool=[]
				this.form.school=e.target.value
				if(this.form.school){
					this.form.mid=this.$store.state.userData.user_id
					this.$axios({
						url:'NewCneProvince/Getschoolone',
						method:'post',
						data:this.form
					}).then(res=>{
						if(!res.data.data.data.length){
							this.$message({
								message:'您搜索的大学不存在！',
								type:'warning'
							})
						}else{
						this.listSchool=res.data.data.data	
						}	
					})
				}else{
					this.$message({
						message:'搜索内容不能为空！',
						type:'warning'
					})
				}
			},
			//点击搜索
			searchAll(){
				this.listSchool=[]
				if(this.form.school){
					this.form.mid=this.$store.state.userData.user_id
					this.$axios({
						url:'NewCneProvince/Getschoolone',
						method:'post',
						data:this.form
					}).then(res=>{
						if(!res.data.data.data.length){
							this.$message({
								message:'您搜索的大学不存在！',
								type:'warning'
							})
						}else{
						this.listSchool=res.data.data.data
						}	
					})
				}else{
					this.$message({
						message:'搜索内容不能为空！',
						type:'warning'
					})
				}
			},
			//跳转学校列表专业
			schoolList(e){
				this.form.school=''
				this.$router.push({path:'/new/searchList',query:{id:e.school_id}})
			}
		}
	}
</script>
<style lang="less" scoped>
.a_content {
	padding-top: 46px;
	box-sizing: border-box;
	width: 1200px;
	height:700px;
	margin: auto;
	background: linear-gradient(270deg, #217CD8 0%, #3030B4 100%);
	.a_title1{
	font-size: 24px;
	font-weight: 400;
	margin-bottom: 22px;
	text-align: center;
	color: #FFFFFF;
	}
	.a_title2{
	font-size: 16px;
	color: #FFFFFF;
	text-align: center
	}
	.a_title3{
	font-size: 16px;
	color: #FFFFFF;	
	text-align: center;
	margin-top: 10px 0px 40px 0px;
	}
	.a_all{
	display: flex;
	align-items: center;
	width: 817px;
	height: 42px;
	background: #F2F8FF;
	margin:40px auto;
	.a_input{
	width:750px;
	height: 42px;
	background: #F2F8FF;
	border: none;
	outline: none;
	padding-left: 30px;
	box-sizing: border-box;
	}
	.a_title4{
	width: 130px;
	height:40px;
	background: #3B68DF;
	border: 1px solid #FFFFFF;
	display: flex;
	align-items: center;
	justify-content: center;
	.iconfont{
		color: #FFFFFF;
	}
	.a_title8{
	font-size: 16px;
	font-weight: 400;
	color: #FFFFFF;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 5px;
	}
	}
	}
	.a_title9:hover{
		color: #FFFFFF;
		background-color: #3B68DF;
		border-bottom: 1px solid #3B68DF;
	}
	.a_title9{
		padding: 0px 10px;
		box-sizing: border-box;
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 40px;
		background-color: #FFFFFF;
		width:817px;
		margin: auto;
		border-bottom: 1px solid #F5F5F5;
	}
	.a_title5{
	font-size: 14px;
	font-weight: 400;
	color: #FFFFFF;
	margin-left:200px;
	margin-top: 40px;
	}
	.a_title6{
	display: flex;
	flex-wrap: wrap;
	width:800px;
	margin-left:200px;
	margin-top: 20px;
	.a_title7{
	padding: 0px 20px;
	height: 30px;
	font-size: 12px;
	font-weight: 400;
	color: #333333;
	background: #F7F7F7;
	border-radius: 9999px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 20px;
	margin-bottom: 10px;
	}
	}
}
</style>
