<template>
	<div>
		<myNav/>
		<div class="content">
			<div>
				<div><img class="k_img" src="@/assets/yishu.png" alt=""></div>
				<div class="k_content">
					<div class="k_all" @click="search(1)">
						<div class="k_title1">
							<i class="iconfont icon-yuanxiao"></i>
						</div>
						<div class="k_title2">院校查询</div>
					</div>
					<div class="k_all" @click="search(2)">
						<div class="k_title1">
							<i class="iconfont icon-zhuanye"></i>
						</div>
						<div class="k_title2">专业查询</div>
					</div>
					<div class="k_all">
						
						<div class="k_title1">
							<i class="iconfont icon-shu"></i>
						</div>
						<div class="k_title2">简章查询</div>
					</div>
					<div class="k_all">
						<div class="k_title1">
							<i class="iconfont icon-chaxun2"></i>
						</div>
						<div class="k_title2">分数查询</div>
					</div>
					<div class="k_all" @click="search(5)">
						<div class="k_title1">
							<i class="iconfont icon-paihangshuju"></i>
						</div>
						<div class="k_title2">排行查询</div>
					</div>
				</div>
				<div class="k_title3">五大优势，安全保障</div>
				<div class="k_title4">
					<div class="k_title5">1.艺术高考解读</div>
					<div class="k_title5">2.涵盖全国各省艺术高考选科数据</div>
					<div class="k_title5">3.全面未来职业规划</div>
					<div class="k_title5">4.权威艺术高考选科系统</div>
					<div class="k_title5">5.艺术高考，普通高考无缝链接</div>
				</div>
			</div>
		</div>
		<Footer/>
	</div>
</template>

<script>
	import Footer from '@/components/footer.vue'
	export default {
		components: {
			Footer
		},
		data() {
			return {

			}
		},
		created(){
			
		},
		methods: {
			//查询
			search(e){
				if(e==1){
						this.$router.push({path:'/Learning'})
				}else if(e==2){
						this.$router.push({path:'/professionalArt'})
				}else if(e==5){
						this.$router.push({path:'/professionalArt'})
				}
			}
		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
	.content {
		.a_all {
			padding: 35px 19px;
			box-sizing: border-box;
			background-color: #FFFFFF;

			.a_title1 {
				font-size: 24px;
				font-weight: 400;
				color: #666666;
				text-align: left;
			}

			.a_title2 {
				display: flex;
				align-items: center;

				.a_title3 {
					margin: 47px 0px;
					font-size: 22px;
					font-weight: 400;
					color: #333333;
				}

				.a_title4 {
					font-size: 22px;
					font-family: PingFang SC;
					font-weight: 400;
					line-height: 43px;
					color: #999999;
					margin-left: 47px;
				}
			}

			.a_title5 {
				font-size: 22px;
				font-weight: 400;
				color: #333333;
				display: flex;
				.a_title6{
					width: 130px;
					font-size: 22px;
					font-weight: 400;
					color: #333333;
					text-align: left;
					display: flex;
				}
				.a_title7{
					font-size: 22px;
					font-family: PingFang SC;
					font-weight: 400;
					line-height: 43px;
					color: #999999;
					margin-left: 47px;
				}
				.a_title8{
					font-size: 22px;
					font-family: PingFang SC;
					font-weight: 400;
					line-height: 43px;
					color:#337CFF;
					margin-left: 47px;
				}
			}
		}
		.b_all{
			display: flex;
			align-items: center;
			margin: 58px 0px 23px 34px;
			.b_title1{
				font-size:24px;
				font-weight: 400;
				color: #666666;
			}
			.b_title2{
				font-size:24px;
				font-weight: 400;
				color:#3B68DF;
			}
		}
		.c_content{
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 35px 36px;
			box-sizing: border-box;
			background-color: #FFFFFF;
			border-bottom: 1px solid #F5F5F5;
			.c_all{
				display: flex;
				align-items: center;
				.c_img{
					width: 110px;
					height: 110px;
					border-radius: 50%;
					margin-right: 23px;
				}
				.c_title1{
					text-align: left;
					.c_title2{
						font-size:20px;
						font-weight: 400;
						color: #333333;
					}
					.c_title3{
						margin: 10px 0px;
						font-size: 18px;
						font-weight: 400;
						color: #999999;
					}
					.c_title4{
						display: flex;
						align-items: center;
						.c_title5{
							width: 41px;
							height: 24px;
							background: #F0F5FF;
							opacity: 1;
							border-radius: 5px;	
							font-size: 14px;
							font-weight: 400;
							color: #337CFF;
							margin-right: 12px;
							display: flex;
							justify-content: center;
							align-items: center;
						}
						.c_title6{
							width: 37px;
							height: 24px;
							background: #FFF1F0;
							border-radius: 5px;	
							font-size: 14px;
							font-weight: 400;
							color: #FF4D4F;
							margin-right: 12px;
							display: flex;
							justify-content: center;
							align-items: center;
						}
						.c_title7{
							width: 33px;
							height: 24px;
							background: #DDF7E2;
							border-radius: 5px;	
							font-size: 14px;
							font-weight: 400;
							color:#3DB159;
							margin-right: 12px;
							display: flex;
							justify-content: center;
							align-items: center;
						}
						.c_title8{
							width: 55px;
							height: 24px;
							background: #F0F5FF;
							border-radius: 5px;	
							font-size: 14px;
							font-weight: 400;
							color:#3B68DF;
							margin-right: 12px;
							display: flex;
							justify-content: center;
							align-items: center;
						}
					}
				}
			}
			.cc_all{
				display: flex;
				.cc_img{
					width: 110px;
					height: 110px;
					border-radius: 50%;
					margin-right: 23px;
				}
				.cc_title1{
					.cc_title2{
						font-size: 20px;
						font-weight: 400;
						color: #333333;
						text-align: left;
					}
					.cc_title3{
						font-size:14px;
						font-weight: 400;
						color: #999999;
						margin: 12px 0px;
						text-align: left;
					}
					.cc_title4{
						display: flex;
						flex-wrap: wrap;
						.cc_title5{
						background: #F0F5FF;
						padding: 5px 10px;
						border-radius: 14px;
						margin-right: 12px;
						margin-bottom: 12px;
						font-size: 12px;
						font-weight: 400;
						color: #337CFF;
						}
					}
				}
			}
			.c_title9{
				.iconfont{
					color: #FECB1E;
					font-size: 30px;
				}
				.c_title10{
					font-size: 30px;
					font-weight: 400;
					margin-left: 9px;
					color: #FECB1E;
				}
			}
		}
		.search_all{
			height: 179px;
			display: flex;
			justify-content: center;
			align-items: center;
			background-color: #FFFFFF;
		.search{
			width: 350px;
			height: 83px;
			background: #337CFF;
			opacity: 1;
			border-radius: 7px;
			font-size:28px;
			font-weight: 400;
			color: #FFFFFF;
			display: flex;
			justify-content: center;
			align-items: center;
		}	
		}
		.a_content{
			display: flex;
			margin-top: 50px;
			height: 100px;
			align-items: center;
			.a_title9{
				font-size:30px;
				font-weight: 400;
				color: #666666;
				display: flex;
				justify-content: center;
				align-items: center;
				height: 100px;
			}
		}
		.d_content{
			width: 1400px;
			background: #F0F5FF;
			border: 5px solid #407EF6;
			padding: 35px 45px;
			box-sizing: border-box;
			margin: 35px 0px;
			.d_title1{
				font-size: 24px;
				font-weight: 500;
				color: #337CFF;
				text-align: left;
			}
			.d_title2{
				font-size: 20px;
				font-family: PingFang SC;
				font-weight: 400;
				line-height: 35px;
				color: #666666;
				margin-top: 20px;
				text-align: left;
			}
		}
		.f_content{
			width: 1200px;
			height: 53px;
			background: #F2F8FF;
			font-size: 24px;
			font-weight: 400;
			color: #333333;
			padding: 23px 49px;
			box-sizing: border-box;
			display: flex;
			text-align: left;
			align-items: center;
			.f_title2{
			font-size: 24px;
			font-weight: 400;
			color: #333333;	
			}
			.f_title3{
				font-size: 20px;
				font-weight: 400;
				color: #337CFF;
				margin-left: 58px;
			}
		}
		.f_all{
			display: flex;
			padding: 23px 49px 0px 49px;
			box-sizing: border-box;
			background-color: #FFFFFF;
			width: 1200px;
			flex-wrap: wrap;
			.f_title1{
				font-size:16px;
				font-weight: 400;
				color: #999999;
				margin: 0px 40px 23px 0px;
			}
		}
		.g_content{
			display: flex;
			// margin-top: 50px;
			height: 100px;
			align-items: center;
			.g_title9{
				font-size:24px;
				font-weight: 400;
				color: #666666;
				display: flex;
				justify-content: center;
				align-items: center;
				height: 100px;
			}
		}
		.k_img{
			width: 1200px;
			height: 549px;
			object-fit: cover;
			margin-top: 20px;
		}
		.k_content{
			display: flex;
			justify-content: space-between;
			background-color: #FFFFFF;
			padding: 40px 3px;
			box-sizing: border-box;
			margin-top: 30px;
			.k_all{
				text-align: center;
				.k_title1{
					width: 163px;
					height: 163px;
					background: #337CFF;
					border-radius: 50%;
					display: flex;
					align-items: center;
					justify-content: center;
					.iconfont{
						color: #FFFFFF;
						font-size: 60px;
					}
				}
				.k_title2{
					margin-top: 23px;
					font-size: 24px;
					font-weight: 400;
					color: #000000;
				}
			}
		}
		.k_title3{
			width: 1200px;
			height: 55px;
			background: #F2F8FF;
			padding-left:49px;
			box-sizing: border-box;
			font-size:22px;
			font-weight: 400;
			color: #333333;
			display: flex;
			align-items: center;
		}
		.k_title4{
			background-color: #FFFFFF;
			padding: 0px 49px 30px 49px;
			box-sizing: border-box;
			.k_title5{
				font-size:18px;
				font-weight: 400;
				color: #999999;
				padding-top: 23px;
				text-align: left;
			}
		}
	}
</style>
