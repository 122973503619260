<template>
	<div>
		<!-- <myNav :loginValue="token"/> -->
		<myNav/>
		<login-pop/>
		<div class="content">
		<div class="g_content">
				<div class="g_all">
					<div style="text-align: left;"><img class="g_img" :src="valueData.logo" alt=""></div>
					<div class="g_title1">
						<div class="g_title2">
							<div class="g_title3">{{detail.schoolName}}</div>
							<div class="g_title4" @click="alternative()" v-if="spare_type==2">备选</div>
						</div>
						<div class="g_title5">
							<div class="g_title6">学校名称：{{detail.schoolName ? detail.schoolName : '-'}}</div>
							<div class="g_title6">高校级别：{{detail.schoolTag ? detail.schoolTag : '-'}}</div>
						</div>
					</div>
				</div>
				<div class="g_title7"></div>
				<div class="gg_content">
					<div class="gg_title1">基本信息</div>
					<div class="gg_all">
						<div class="gg_title2">
							<div class="gg_title3">学校类型：{{detail.schoolCategoryName}}</div>
							<div class="gg_title3">所在省市：{{detail.cityName}}</div>
						</div>
						<div class="gg_title2">
							<div class="gg_title3">办学性质：{{detail.schoolAttributeName}}</div>
							<div class="gg_title3">办学类型：{{detail.diplomaName}}</div>
						</div>
					</div>
				</div>
				<div class="g_content1">
					<div @click="xuanZe(myIndex)" :class="index1==myIndex ? 'g_next1' : 'g_next2'" v-for="(items,myIndex) in list2" :key="myIndex">{{items.name}}</div>
				</div>
				<div class="g_title8"></div>
				<div>
					<div class="g_title9" v-if="index1==0">
						<div class="g_title10">概况简介</div>
						<div class="g_title15">
							<div class="g_title14">
								{{detail.introduction}}
							</div>
						</div>
					</div>
					<div class="g_title9" v-if="index1==1">
						<div class="g_title9">
							<div class="g_title10">地区概率</div>
							<div class="h_content" v-for="(item,index) in j_data" :key="index">
								<div class="h_title1">{{item.area}}</div>
								<div class="h_all">
									<div class="h_title2">
										<div class="h_title3" :style="{width: item.rate + '%'}"></div>
									</div>
									<div class="h_title4">{{item.rate}}%</div>
								</div>
							</div>
						</div>
						<div class="g_title9">
							<div class="g_title10">行业概率</div>
							<div class="h_content" v-for="(item,index) in h_data" :key="index">
								<div class="h_title1">{{item.name}}</div>
								<div class="h_all">
									<div class="h_title2">
										<div class="h_title3" :style="{width: item.rate + '%'}"></div>
									</div>
									<div class="h_title4">{{item.rate}}%</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<Footer/>
	</div>
</template>

<script>
	import Footer from '@/components/footer'
	export default {
		components: {
			Footer
		},
		data() {
			return {
				school_id: 0,
				// 学校列表 - 详情
				detail: {}, // 学校列表
				valueData:{},
				spare_type:'',
				index1:0,
				list2:[{name:'学校概括'},{name:'录取概率'}],
				j_data:[],
				h_data:[]
			}
		},
		activated() {
			this.index1=0
			this.valueData={schoolID:this.$route.query.id,logo:this.$route.query.data,id:this.$route.query.mid}
			this.details(this.valueData.schoolID)
		},
		methods: {
			xuanZe(i) {
				this.index1 = i
				if(this.index1==1){
					this.$axios({
						url: 'major/major_job',
						method:'post',
						data: {id:16}
					}).then(res => {
						this.j_data=res.data.data.address
						this.h_data=res.data.data.industry
					})
				}
			},
			//详情数据公共函数
			details(e){
				this.$axios({
					url: 'art/schoolprofile',
					method: 'POST',
					data: {
						schoolID:e
					}
				}).then(res=>{
					this.detail=res.data.data
				})
			},
			//加入备选和移除备选
			alternative(){
				if(this.spare_type==2){
					this.$axios({
						url:'Spare/add',
						method:'post',
						data:{
							member_id:this.$store.state.userData.user_id,
							school_id:this.$store.state.shcoolDetail.school_id
						}
					}).then(res=>{
						if(res.data.status==200){
							this.spare_type=1
							this.$store.commit('detail',{school_id:this.school_id,spare_type:1})
							this.$message({
								message:'添加备选成功',
								type:'warning'
							})
						}
					})
				}
				// else{
				// 	this.$axios({
				// 		url:'Spare/add',
				// 		method:'post',
				// 		data:{
				// 			id:this.$store.state.userData.user_id
				// 		}
				// 	}).then(res=>{
				// 		if(res.data.status){
				// 			this.spare_type=2
				// 			this.$store.commit('detail',{school_id:this.school_id,spare_type:this.spare_type})
				// 			this.$message({
				// 				message:'移除备选成功',
				// 				type:'success'
				// 			})
				// 		}
				// 	})
				// }
			},
		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
	.content {
		.g_content{
			background-color: #FFFFFF;
			width: 1200px;
			margin: auto;
			padding: 30px 0px;
			box-sizing: border-box;
			.g_all{
				display: flex;
				align-items: center;
				.g_img{
					width: 100px;
					height: 100px;
					margin:0px 20px 0px 30px;
					border-radius: 50%;
				}
				.g_title1{
					.g_title2{
						display: flex;
						align-items: center;
						.g_title3{
							font-size:24px;
							font-weight: 400;
							color: #333333;
							margin-right: 20px;
						}
						.g_title4{
							width:60px;
							height: 21px;
							background: #337CFF;
							border-radius: 10px;
							font-size: 12px;
							font-weight: 400;
							color: #FFFFFF;
							display: flex;
							align-items: center;
							justify-content: center;
						}
					}
					.g_title5{
						display: flex;
						align-items: center;
						margin-top: 26px;
						.g_title6{
							font-size: 16px;
							font-weight: 400;
							color: #999999;
							margin-right: 30px;
						}
					}
				}
			}
			.g_title7{
				width: 1140px;
				height: 0px;
				border: 2px solid #CCCCCC;
				margin:22px auto;
			}
			.gg_content{
				padding: 0px 30px;
				box-sizing: border-box;
				background-color: #FFFFFF;
				.gg_title1{
					font-size: 20px;
					font-weight: 400;
					color: #3B68DF;
					margin-bottom: 10px;
					text-align: left;
				}
				.gg_all{
					display: flex;
					.gg_title2{
						margin-right: 108px;
						text-align: left;
						.gg_title3{
							font-size: 16px;
							font-weight: 400;
							color: #666666;
							margin-bottom: 10px;
						}
					}
				}
			}
			.g_content1{
				display: flex;
				justify-content:space-between;
				.g_next1{
					width:600px;
					height: 55px;
					background: #F2F8FF;
					font-size: 20px;
					font-weight: 400;
					color: #337CFF;
					display: flex;
					align-items: center;
					justify-content: center;
					box-sizing: border-box;
					border-bottom: 2px solid #337CFF;
				}
				.g_next2{
					width:600px;
					height: 53px;
					font-size: 20px;
					font-weight: 400;
					color:#333333;
					display: flex;
					align-items: center;
					justify-content: center;
					border-bottom: 2px solid #337CFF;
				}
			}
			.f_content1{
				text-align: center;
				width:240px;
				// .f_all{
				// 	font-size: 20px;
				// 	font-weight: 400;
				// 	color: #337CFF;
				// 	margin-right: 20px;
				// 	border-bottom: 2px solid #337CFF;
				// 	padding-bottom: 10px;
				// }
				.f_all:hover{
					color: #337CFF;
					background-color: #F2F8FF;
				}
				.f_title:hover{
					color: #337CFF;
					background-color: #F2F8FF;
				}
				.f_title{
					font-size: 20px;
					font-weight: 400;
					height: 40px;
					padding-bottom: 10px;
					display: flex;
					align-items: center;
					justify-content: center;
				}
			}
			// .g_title8{
			// 	width: 1200px;
			// 	height: 2px;
			// 	background: #337CFF;
			// }
			.g_title9{
				.h_content{
					margin-left: 32px;
					.h_title1{
						font-size: 20px;
						font-weight: 400;
						color: #333333;
					}
					.h_all{
						display: flex;
						align-items: center;
						margin: 7px 0px;
						.h_title2{
						width: 570px;
						height: 16px;
						background: #F5F5F5;
						border-radius: 99px;
							.h_title3{
							width: 292px;
							height: 16px;
							background: #337CFF;
							border-radius: 99px;
							}
						}
						.h_title4{
							font-size: 16px;
							font-weight: 400;
							color: #333333;
							margin-left: 50px;
						}
					}
				}
				.img_all{
					display: flex;
					flex-wrap: wrap;
					.g_imge{
						width: 270px;
						height: 203px;
						background: #F5F5F5;
						border-radius: 10px;
						margin: 23px 15px;
					}
				}
				.g_title10{
					font-size: 24px;
					font-weight: 400;
					color: #333333;
					margin: 30px 0px 20px 30px;
					text-align: left;
				}
				.g_title11{
					font-size: 20px;
					font-weight: 400;
					color: #333333;
					margin:0px 30px 0px 30px;
				}
				.g_title12{
					font-size: 20px;
					font-weight: 400;
					color: #337CFF;
					margin-left: 32px;
				}
				.g_title13{
					padding: 5px 15px;
					background: #F5F5F5;
					border-radius: 10px;
					font-size: 16px;
					font-weight: 400;
					color: #666666;
					display: flex;
					align-items: center;
					justify-content: center;
					margin-top: 10px;
					margin-right: 20px;
					box-sizing: border-box;
				}
				.yuan{
					width: 12px;
					height: 12px;
					background: #337CFF;
					border-radius: 50%;
				}
				.g_title15{
					display: flex;
					align-items: center;
					margin-left: 32px;
					margin-bottom: 12px;
					.g_title14{
						padding-right:14px;
						box-sizing: border-box;
						font-size: 20px;
						font-weight: 400;
						color: #333333;
						text-align: left;
						text-indent:40px;
					}
				}
				.g_title16{
					width: 571px;
					height: 60px;
					display: flex;
					align-items: center;
					justify-content: center;
					background: #F2F8FF;
					border: 1px solid #CCCCCC;
					font-size: 20px;
					font-weight: 400;
					color: #333333;
				}
			}
		}
	}
</style>
