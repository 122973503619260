<template>
	<div>
		<div style="position: relative;background:rgba(0,0,0,.3)">
			<div style="display: flex;align-items: center;justify-content:space-between;">
				<img class="logo_img" :src="myData.pcAppLogo? myData.pcAppLogo.img : ''" alt="">
				<div class="backes" @click="backeTo()">返回首页</div>
			</div>
			<div class="a_title1">愿必达APP,不浪费你的每一分</div>
			<div class="a_title2">国内领先的智能规划填报平台</div>
			<div class="a_all">
				<div class="a_title3" style="margin-right: 80px;text-align: center;">
					<div><img class="a_title5" :src="myData.gzh? myData.gzh.img : ''" alt=""></div>
					<div class="a_title4">{{myData.gzh? myData.gzh.remarks : ''}}</div>
				</div>
				<div class="a_title3" style="text-align: center;">
					<div><img class="a_title5" :src="myData.app? myData.app.img : ''" alt=""></div>
					<div class="a_title4">{{myData.app? myData.app.remarks : ''}}</div>
				</div>
			</div>
			<div style="text-align: center;margin-top: 246px;">
				<i @click="nextTo()" class="iconfont icon-wangxiala" style="font-size:40px;color:#FFFFFF;"></i>
			</div>
			<div class="bg_img" style=";">
				
			</div>
		</div>
		<div class="a_content">
			<div class="b_all">
				<div><img class="b1" src="@/assets/p_img.png" alt=""></div>
				<div class="b_title4">
					<div class="b_title1">将大学放进口袋</div>
					<div class="b_title2">让梦想成为现实</div>
					<div class="b_title2">300 万考生的选择，不浪费 1 分上好大学！</div>
					<div><img class="b2" src="@/assets/img3.png" alt=""></div>
				</div>
			</div>
			<div class="b_all">
				<div class="b_title4">
					<div class="b_title1">一键智能推荐适合院校</div>
					<div class="b_title2" style="width: 400px;">依据考生分数，结合专业意向，按录取难易度，推荐最适合的大学和专业。</div>
					<div><img class="b2" src="@/assets/img1.png" alt=""></div>
				</div>
				<div><img class="b1" src="@/assets/img2.png" alt=""></div>
			</div>
			<div class="b_all">
				<div><img class="b1" src="@/assets/img4.png" alt=""></div>
				<div class="b_title4">
					<div class="b_title1">将大学放进口袋</div>
					<div class="b_title2" style="width: 400px;">用文字、图片、语音的方式一起话大学、聊专业、谈未来、想未来。</div>
					<div><img class="b2" src="@/assets/img5.png" alt=""></div>
				</div>
			</div>
		</div>
		<Footer/>
	</div>
</template>

<script>
	import Footer from '@/components/footer.vue'
	export default {
		components: {
			Footer
		},
		data() {
			return {
				myData:{},
				moveHeight:0
			}
		},
		created(){
			this.dataAll()
		},
		// mounted() {
		// if (this._isMobile()) {
		// alert('手机端');
		// //移动端首页路由-->
		// }else {
		// alert('pc端');
		// //pc端首页路由-->
		// }
		// },
		methods: {
			// 判断移动端还是pc端
			//_isMobile方法
			// _isMobile(){
			// let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
			// return flag;
			// },
			//获取所有数据
			dataAll(){
				this.$axios({
					url:'Pcimg/pcxxx',
					methods:'post'
				}).then(res=>{
					this.myData=res.data.data	
				})
			},
			//返回首页
			backeTo(){
				this.$router.push({path:'/index'})
			},
			//向下滚动
			nextTo(){
				let timer = setInterval(() => {
				document.documentElement.scrollTop =document.documentElement.scrollTop+108
				if (document.documentElement.scrollTop==1080) {
				clearInterval(timer)
				}
				},10)
				document.documentElement.scrollTop=0
			}
		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
	.logo_img{
		margin: 40px 0px 0px 40px;
		width: 180px;
	}
	.backes{
		margin: 40px 40px 0px 0px;
		color: #FFFFFF;
		font-size: 18px;
	}
	.a_title1{
		font-size:60px;
		font-weight: 600;
		color: #FFFFFF;
		text-align: center;
		margin-top:247px;
		margin-bottom:30px;
	}
	.a_title2{
		font-size:20px;
		font-weight: 400;
		color: #FFFFFF;
		text-align: center;
		letter-spacing:10px;
	}
	.a_all{
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 40px;
		.a_title3{
			.a_title5{
				width:100px;
				height:100px;
			}
			.a_title4{
				text-align: center;	
				font-size: 14px;
				font-weight: 400;
				color: #FFFFFF;
				margin-top: 18px;
			}
		}
	}
	.bg_img{
		background-image: url(../../assets/tu1.jpg);
		background-repeat:repeat;
		background-size:100% 100%;
		height:1080px;
		width:100%;
		position: absolute;
		top: 0px;
		z-index: -9999;
	}
		.a_content{
			width: 1200px;
			margin: auto;
			.b_all{
				display: flex;
				margin-top: 247px;
				width: 1200px;
				justify-content: space-around;
				.b1{
					width: 400px;
				}
				.b_title4{
					.b_title1{
						font-size: 30px;
						font-weight: 400;
						color: #333333;
						margin-top: 95px;
					}
					.b_title2{
						font-size:20px;
						font-weight: 400;
						color: #333333;
						margin-top: 20px;
					}
					.b2{
						width: 500px;
					}
				}
			}
		}
</style>
