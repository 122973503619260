<template>
	<div>
		<myNav/>
		<login-pop/>
		<vipPop/>
		<div class="content">
			<div class="c_content">
				<div class="c_one">
					{{listData.title}}
				</div>
				<div>
					<div class="c_two">
						测试介绍
					</div>
					<div class="c_three">{{listData.count}}</div>
				</div>
				<div class="c_five">
					测前须知
				</div>
				<div class="c_four">
					<div id="a">1、请务必诚实、独立的回答问题，只有如此，才能得到有效的结果。</div>
					<div id="b">2、每一题目请以第一印象作答，不必仔细推敲，答案没有好坏、对错之分，根据自己的实际情况做答。</div>
					<div id="c">3、报告结果没有“好”与“坏”之分，但不同的特点对于不同的专业选择会存在“合适”和“不合适”的区别，从而表现出具体条件的优势和劣势。</div>
					<div id="d">4、本报告给广大考生和家长提供了一种科学的方法，一份可靠的依据，从而让考生和家长能了解和接触更多的可能，而并非限制考生的填报。</div>
					<div id="e">5、所有测评结果的得分仅代表考生本人各种指标之间的相对水平，而不代表考生的这些指标在人群总体中的绝对水平。</div>
					<div id="f">6、只要你是认真、真实地填写了测试问卷，那么通常情况下你都能得到确实和你相匹配的专业。希望你能从中或多或少地获得一些有益的信息。</div>
				</div>
				<div class="btn" @click="Btn">
					开始测试
				</div>
			</div>
		</div>
		<Footer/>
	</div>
</template>

<script>
	import Footer from '@/components/footer'
	export default {
		components: {
			Footer
		},
	
		data() {
			return {
				show:false,
				listData:{}
			}
		},
		activated(){
			if(!this.$store.state.userData.token) return
			this.listData=this.$store.state.ceshiIndex
		},
		methods: {
		Btn(){
			this.show=true,
			this.$router.push({path:'/ceshi/Test',query:{id:this.listData.id}})
		}
		}
	}
</script>

<style lang="less" scoped>
	.content{
		.c_content{
			width:1200px;
			height: 1000px;
			border: 0.0625rem solid rgb(223,235,255);
			background: #FFFFFF;
			margin-top: 30px;
			
			.c_one{
				width: 1200px;
				height: 80px;
				font-size: 28px;
				background: #F2F8FF;
				display: flex;
				align-items: center;
				box-sizing: border-box;
				padding-left: 20px;
			}
			.c_two{
				width: 150px;
				height: 33px;
				font-size: 24px;
				font-weight: 400;
				line-height: 33px;
				color: #333333;
				margin: 36px 0px 0px 10px;
				box-sizing: border-box;
				padding-left: 10px;
			}
			.c_three{
				margin: 4px;
				width: 1109px;
				padding: 20px 0px 40px 20px;
				font-size: 18px;
				font-weight: 400;
				color: #333333;
				text-indent: 50px;
				box-sizing: border-box;
			}
			.c_five{
				box-sizing: border-box;
				padding-left: 20px;
				font-size: 24px;
			}
			.c_four{
				width: 547px;
				font-size: 18px;
				font-family: PingFang SC;
				font-weight: 400;
				line-height: 34px;
				color: #333333;
				box-sizing: border-box;
				padding-left: 10px;
				div{
					padding: 5px;
				}
				#a{
					width: 600px;
					height: 25px;
					font-size: 18px;
					font-family: PingFang SC;
					font-weight: 400;
					line-height: 34px;
					color: #333333;
				}
				#b{
					width: 821px;
					height: 25px;
					font-size: 18px;
					font-family: PingFang SC;
					font-weight: 400;
					line-height: 34px;
					color: #333333;
				}
				#c{
					width: 1200px;
					height: 25px;
					font-size: 18px;
					font-family: PingFang SC;
					font-weight: 400;
					line-height: 34px;
					color: #333333;
				}
				#d{
					width: 1127px;
					height: 25px;
					font-size: 18px;
					font-family: PingFang SC;
					font-weight: 400;
					line-height: 34px;
					color: #333333;
				}
				#e{
					width: 947px;
					height: 25px;
					font-size: 18px;
					font-family: PingFang SC;
					font-weight: 400;
					line-height: 34px;
					color: #333333;
				}
				#f{
					width: 1145px;
					height: 25px;
					font-size: 18px;
					font-family: PingFang SC;
					font-weight: 400;
					line-height: 34px;
					color: #333333;
				}
			}
			.btn{
				border: 1px solid;
				width: 120px;
				height: 40px;
				background: #337CFF;
				margin:100px auto;
				color: white;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
	}
</style>
